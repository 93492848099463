import { motion } from "framer-motion"
import styled from "styled-components"

export const Box = styled(motion.div)`
width:80%;
margin:0 auto;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 1.5rem;
padding-top: clamp(70px, 25vh, 220px);
margin:-10% auto 0 auto;
@media screen and (max-width: 960px) {
	grid-template-columns: repeat(1, 3fr);
	grid-gap: 1.5rem;
	margin:-50% auto 0 auto;
}
`
export const BoxGroup = styled(motion.div)`
border:1px solid #DFDFDF;
border-radius:2rem;
margin:10px 0;
p{
    padding:0 6%;
    color:grey;
}
@media screen and (max-width: 960px) {
	margin:0;
}
`
export const BoxText = styled(motion.h3)`
padding:3% 6%;
padding-top:0;
`
export const Img = styled(motion.img)`
width:100%;
height:60%;
padding:6%;
border-radius:3rem;
display: inline-block;
	@media screen and (max-width: 768px) {
		width:90%;
	}

`;
export const Imgfon = styled(motion.img)`
  width:100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	z-index: 50;
	padding:1.5%;
	@media screen and (max-width: 768px) {
		width:100%;
	}
`;