import React from 'react';
import { HeroPart, HeroSection, Img, Img1,Container, HeroText  } from '../About/AboutStyles';
import Pricing from '../Pricing/Pricing';
import Contact from '../Contact/Contact';
import Case from '../Case/Case';
import { Heading} from '../Hero/HeroStyles';

const NavPricing = () => {
    return (
        <div>
            <HeroSection >
                <Container>
                    <HeroPart >
                        <Img1 src="./assets/fonabout.png" />
                        <Heading><span>Transparent Pricing </span> <span>Superior Value</span></Heading>
                        <HeroText>
                            We integrate quality engineering and best practices across your IT
                            infrastructure and enhance application performance.
                        </HeroText>
                    </HeroPart>
                    <HeroPart>
                    <div style={{border:'3px solid #100C34', borderRadius:'50%'}}>
                        <Img src="./assets/navlinkimg.png" alt="menu" />
</div>
                    </HeroPart>
                </Container>
            </HeroSection>
            <Pricing/>
            <Contact/>
            <Case/>
        </div>
    );
}

export default NavPricing;
