import {
    Container,
    Button,
    Grid,
    Paper,
  } from "@mui/material";
  import DataTable from 'react-data-table-component';
  import '@fortawesome/fontawesome-free/js/all.js';
  import React, { useState, useEffect,useMemo} from "react";
  import Axios from "axios";
  import {config, getUserId , getUrlVars , divStyle , paperCss} from '../Utils/Constants';

  const HistoryList = () => {  
    const [loaded , isLoaded]               = useState(0);
    const [data , setData]                  = useState([]);
    const [uid , setUserId]                 = useState(0);
    const [processing,setProcessing]        = useState(0);
    useEffect(() => {
      try 
      {
                if(getUserId() == '')
                    window.location = '/';
                else
                {
                    isLoaded(1)
                    setUserId(getUserId());
                }
      } catch (error) {
        console.log(error);
      }    
    });



    const loadData = () => {
      if(processing)
          return;
      setProcessing(1);
      Axios.post(config.api_url,{"type" : "getHistory" ,"uid" : uid ,"reference" :getUrlVars()['reference'] }).
      then((res) => {
              if(res.data['success'] == "1")
              {
                  setData(res.data.data);
              }
              
      });


    }

    const play = (row) => {
        window.open(row['link'], '_blank').focus();
    }

    
    const columns              = useMemo(() => [ { name: '#', selector: row => row.index, sortable: false,width: "45pt", }, { name: 'Dated', selector: row => row.dated, sortable: true, width: "140pt",}, 
                                  { 
                                      cell : row => ( <> {<Button  onClick={() => play(row)}  color="success"><i class="fa fa-eye"></i>&nbsp;View</Button>} </> ), width : '140pt' 
                                  }],[]);

    return (
      <>
      { loaded ?  ( <>
          <div onLoad={loadData()} style={divStyle}>
            <Container>
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
              >
                <Paper elelvation={2} sx={paperCss}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                      <DataTable
                          columns={columns}
                          data={data}
                          pagination
                      />
                      </Grid>
                      
                    </Grid>
                </Paper>
              </Grid>
            </Container>
          </div>
        </> ) : <></> }
        </>
    );
  };
  
  export default HistoryList;
  
  