import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormSection = styled.div`
background: #0E0817;
padding: 160px 0;
position:relative;
z-index:0;
`;

export const FormTitle = styled.h1`
	margin-bottom: 24px;
	font-size: 40px;
	line-height: 1.1;
	font-weight: 450;
	background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	
`;

export const FormContainer = styled.div`
	display: flex;
`;
export const FormColumn = styled.div`
margin:0 45% 0 auto;

	/* margin-bottom: 15px; */
	background: transparent;
	padding: 50px;
	border: 20px;
	/* padding: ${({ small }) => (small ? '0 50px' : '0 15px')}; */
	flex: 1;
	max-width: 40%;
	display: flex;
	justify-content: start;
	align-items: start;
	border-radius: 20px;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 95% !important;
		flex-basis: 100%;
		margin:0 auto;
	}
	}
`;

export const FormRow = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	
`;

export const FormWrapper = styled.form`
	/* max-width: 540px; */
	padding-top: 0;
	width: 100%;
	a{
		color:#7C78F0;
	}
	p{
		color:white;
		text-align:center;
		padding-top:5%;
	}
	h6{
		padding:4% 0;
		font-size:0.75rem;
		letter-spacing:-0.2px;
		color:white;
		font-weight:300;
	}
`;

export const FormMessage = styled(motion.div)`
	color: ${({ error }) => (error ? 'red' : 'white')};
	padding: 5px;
	text-align: center;
	margin-top: 1rem;
`;

export const FormInputRow = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;
	background:white;
	> p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
`;
export const FormInput = styled.input`
	display: block;
	padding-left: 10px;
	outline: none;
	border-radius: 2rem;
	height: 40px;
	width: 100%;
	border: none;
	border-bottom: 1px solid grey;
	font-size: 1rem;
	background:white;
	border:1px solid #818181;

	&::placeholder {
		color: grey; 
		opacity: 0.5;
	  }
`;

export const FormLabel = styled.label`
	display: inline-block;
	font-size: 0.9rem;
	margin-bottom: 0.3rem;
	color:white;
	&::placeholder 
		color: grey;
	  }

`;
export const VisiblityPassword = styled.div`
	position:absolute;
	padding-top:1.8%;
	left:49%;	
	color:#8F00BF;
	z-index:50;
	cursor:pointer;
@media screen and (max-width: 768px) {
	padding-top:5.5%;
	position:absolute;
	left: 75%;
}
`
export const FormImgWrapper = styled.div`
	max-width: 555px;
	display: flex;
	justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
	
`;
export const FormImg = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	max-height: 500px;
`;

export const FormButton = styled.button`
	border-radius: 4px;
	background: #8F00BF;
	margin-top: 1.5rem;
	white-space: nowrap;
	color: white; 
	outline: none;
	width: 100%;
	font-size: 1.4rem;
	padding: 5px 15px;
	border: 1px solid #818181;
	border-radius:2rem;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover {
		color: white;
		transition: background-color 0.6s ease-in;
		background-color: #636CFF;
	}
`;

export const Img1 = styled.img`
	position:absolute;
	right:0;
	top:-80px;
	z-index: -1;
	width:45%;
	// transform: rotate(90deg);
	@media screen and (max-width: 768px) {
		width:75%;
		top:0;
	`
export const Img2 = styled.img`
	position:absolute;
	left:0;
	bottom:0;
	z-index: -1;
	width:25%;
	@media screen and (max-width: 768px) {
		width:55%;
	`;
