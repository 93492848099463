import React, { useEffect, useRef, useState } from 'react';
import { Container,ContentColumn,ContentRow,Heading, HeroPart, HeroSection, HeroText, Img, Img1, ImgWrapper, TopLine } from '../About/AboutStyles';
import { TextWrapper } from '../../globalStyles';
import { Heading1, FormInput, FormInputRow, Button,  ContactTitle, InputTitle, InputWrapper, StyledReCAPTCHA,  EmailButton } from './LinkContactStyles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { config,getUserId } from '../../Utils/Constants';
import { Alert, Snackbar } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Subtitle } from '../About/AboutStyles';
const LinkContact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const bodyRef = useRef(null);
  const location = useLocation();
  const SITE_KEY = "6Le5qMopAAAAAJOyM0iVqASkz1Wodl9OplituUw_"
  useEffect(() => {
    if (location.pathname === '/contact' && bodyRef.current) {
      bodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    if (!recaptchaValue) {
      setErrorMessage("Please verify Captcha");
      return;
    }

    const formData = {
      name,
      phone,
      email,
      subject,
      message,
      type: "contactemail",
      uid: getUserId(),
      "g-recaptcha-response": recaptchaValue
    };

    fetch(config.api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "accept": "application/json",
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        setIsSubmitting(true);
        console.log(data);
        setName('');
        setPhone('');
        setEmail('');
        setSubject('');
        setMessage('');
      })
      .catch(error => {
        console.error(error);
      });
  };
  return (
    <div>
      <HeroSection >
        <Container>
          <HeroPart >
            <Img1 src="./assets/fonabout.png" />
            <Heading><span>Reach Out  </span> <span>Connect Today</span></Heading>
            <HeroText>
              We integrate quality engineering and best practices across your IT
              infrastructure and enhance application performance.
            </HeroText>
          </HeroPart>
          <HeroPart>
            <div style={{ border: '3px solid #100C34', borderRadius: '50%' }}>
              <Img src="./assets/linkcontact.png" alt="menu" />
            </div>
          </HeroPart>
        </Container>
      </HeroSection>
      <ContentRow>
        <ContentColumn>
          <ImgWrapper>
            <Img
              src='./assets/img_contact.jpg'
              alt='foto'
            />

          </ImgWrapper>
        </ContentColumn>
        <ContentColumn style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <TextWrapper>
            <TopLine id="about">
              Reach us
            </TopLine>
            <Heading1>
              Get in Touch
            </Heading1>
            <Subtitle>
              Are you looking to optimize your performance monitoring solutions and unlock new levels of efficiency for your business? Reach out to us today, and let's explore how our clear and competitive pricing, along with our unmatched industry expertise, can deliver tremendous value for your organization. Our team is dedicated to understanding your unique requirements and developing customized solutions that drive tangible improvements in your operations. Don't settle for anything less than the best - connect with us now, and let's get started on elevating your performance monitoring capabilities.
            </Subtitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ContactTitle>Click to contact us</ContactTitle>
              <EmailButton type='submit'><a href="mailto:info@crewformance.com">info@crewformance.com</a></EmailButton>
            </div>

          </TextWrapper>
        </ContentColumn>
      </ContentRow>
      <InputWrapper ref={bodyRef}>
        <InputTitle>Contact form</InputTitle>
        <form onSubmit={handleSubmit}>
          <FormInputRow style={{ background: 'transparent' }}>
            <FormInput
              placeholder='Name'
              type='text'
              name='name'
              value={name}
              required
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
            <FormInput
              placeholder='Your Phone'
              type='number'
              name='phone'
              value={phone}
              required
              maxLength={50}
              onChange={(e) => setPhone(e.target.value)}
            />
            <FormInput
              placeholder='Your Email'
              type='email'
              name='email'
              value={email}
              required
              maxLength={100}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormInput
              style={{}}
              placeholder='Subject'
              type='text'
              name='subject'
              value={subject}
              required
              maxLength={50}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FormInputRow>

          {
          errorMessage === "" ?
          <Snackbar
                open={isSubmitting}
                autoHideDuration={2000}
                onClose={() => setIsSubmitting(false)}
                >
                <Alert
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
              >
                {errorMessage==="" ? 'Sending contact form...' : null}
                </Alert>
              </Snackbar>
          :<div></div>
          } 
          <textarea
            style={{ background: 'white', height: '30vh', padding: '14px', borderRadius: '2rem', border: '1px solid #818181' }}
            placeholder='What`s on your mind?'
            name='message'
            value={message}
            required
            maxLength={800}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <StyledReCAPTCHA>
             <ReCAPTCHA 
              sitekey={SITE_KEY} onChange={handleRecaptchaChange}/>
            </StyledReCAPTCHA>
                {errorMessage && 
                <Snackbar
                open={isSubmitting}
                autoHideDuration={1300}
                onClose={() => setIsSubmitting(false)}
                >
                <Alert
                severity='error'
                variant="filled"
                sx={{ width: '100%' }}
              >
                {errorMessage}
                </Alert>
              </Snackbar>
              }
            <Button type='submit'>Submit</Button>
        </form>
      </InputWrapper>
    </div>
  );
}

export default LinkContact;
