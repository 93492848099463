import styled from "styled-components";
import { Button } from "../../globalStyles";
import { motion } from "framer-motion";

export const HeroSection = styled.section`
  background: #0E0817;
  height:100vh;
    position: relative;
    z-index: 1;
  display: flex;
  flex-wrap: wrap;
	align-items: center;
	flex-direction: row;
	justify-content: space-around;
	object-fit: cover;
  overflow: hidden;
  padding-top: clamp(70px, 25vh, 220px);
  @media screen and (max-width: 768px) {
	  flex-direction: column-reverse;
    padding-top: 0;
  }
`;
export const Container = styled.div`
	width: 80%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 5px;
	object-fit: cover;
	display: flex;
	justifyContent: center;
	@media screen and (max-width: 960px) {
    width:95%;
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		justify-content:center;
		align-items: center;
		
	}
`;
export const HeroPart = styled.div`
  width:50%;
  @media screen and (max-width: 768px) {
    width:100%;
    margin-bottom:10%;
}
}
`;
  export const Heading = styled(motion.h2)`
  margin-top:4.5%;
	margin-bottom: 24px;
	line-height: 1.2;
	font-weight: 600;
  letter-spacing:-1px;
  z-index:1;
  span:first-child {
	  font-size: 2.9rem;
    background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	@media screen and (max-width: 960px) {
    font-size:2rem;
  }
  }
  span:last-child {
    color: white;
	  font-size: 2.8rem;
    @media screen and (max-width: 960px) {
      font-size:2rem;
    }
  }
	@media screen and (max-width: 768px) {
		font-size: 2rem;
		line-height: 1.1;
		margin-top:35%;

	}
`;
export const Img = styled(motion.img)`
  width:110%;
  // width:600px; 
  // padding-top: 30px; 
  // padding-bottom: 50px;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	z-index: 50;
	@media screen and (max-width: 768px) {
		width:100%;
    margin-top:10%;
	}
`;


export const HeroText = styled.p`
  margin-bottom: 35px;
  font-size: clamp(0.7rem, 1.3vw, 1.1rem);
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
`;

export const ButtonWrapper = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
		display:flex;
    width: 100%;
    justify-content: start;
    flex-flow: wrap;
    gap: 0.5rem;
  }
`;

export const HeroButton = styled(Button)`
  color: #fff;
  border: 2px solid #5eb886;
  font-size: 1rem;
  letter-spacing: 1px;
  background:transparent;
  &:before {
    height: 500%;
  }

  &:hover:before {
    height: 0%;
    
  }

  &:hover {
    color: #fff;
    border-style: solid;
    color:blue; 
  }
`;
export const FormInputRow = styled.div`
display: flex;
justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;
  margin-top:5%;
  position:relative;
	> p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
`;
export const FormInput = styled.input`
	display: block;
	padding-left: 5%;
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 80%;
	border: none;
	border-bottom: 1px solid #818181;
	font-size: 1rem;
  @media (max-width: 768px) {
    ::placeholder{
      font-size:0.01rem;
    }
  }

`;

export const FormLabel = styled.label`
	display: inline-block;
	font-size: 0.9rem;
	margin-bottom: 0.3rem;
	color: #afafaf;
`;

export const FormButton = styled.button`
  min-height:100%;
  border-radius:3rem;
  padding:2.15% 5%;
  background:#8F00BF;
  color:white;
  position:absolute;
  right:15%;
  bottom:1;
  top:0;
  border:none;
  cursor:pointer;
`

export const Img1 = styled.img`
position:absolute;
right:0;
top:-80px;
z-index: -1;
width:35%;
@media (max-width: 960px) {
		width:50%;
    top:0px;
}
`
export const Img2 = styled.img`
position:absolute;
left:0;
bottom:0;
z-index: -1;
width:35%;
@media (max-width: 768px) {
		width:60%;
}
`