import React, { useState, useEffect } from "react";
import { FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavIcon,
	MobileIcon,
	NavMenu,
	NavLinks,
	NavItem,
	NavReg,
	Button,
} from './NavbarStyles.js';
import './Navbar.css';
import { useLocation, useHistory } from 'react-router-dom';
import { data, data_reg } from '../../data/NavbarData';
import { getUserId } from '../../Utils/Constants';
import { Link } from "react-router-dom";


const Navbar = () => {
	const [show, setShow] = useState(false);
	const [loggedin, setLoggedIn] = useState(0);
	const [isSticky, setIsSticky] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsSticky(true);
				setIsScrolled(true);
			} else {
				setIsSticky(false);
				setIsScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		setLoggedIn((getUserId() != ''))
	})

	let history = useHistory();
	let location = useLocation();

	const handleClick = () => {
		setShow(!show);
	};

	const scrollTo = (id) => {
		const element = document.getElementById(id);

		element.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const closeMobileMenu = (to, id) => {
		if (id && location.pathname === '/') {
			scrollTo(id);
		}

		history.push(to);
		setShow(false);
	};

	return (
		<>

			<Nav className={`${isSticky ? "isSticky" : ""} ${isScrolled ? "isScrolled" : ""}`}>
				<NavbarContainer>
					<NavLogo to="/">
						<NavIcon src="./assets/logo.png" alt="logo" />
					</NavLogo>
					<MobileIcon onClick={handleClick}>
						{show ? <FaTimes /> : <CgMenuRight />}
					</MobileIcon>
					<div>
						{loggedin == 1 ?
							<NavMenu show={show}>
								{data_reg.map((el, index) => (
									<NavItem key={index} whileHover={{ scale: 1.07 }}>
										{el.isExternal ? (
											<NavLinks as="a" href={el.to} target="_blank" rel="noopener noreferrer" className={isScrolled ? "isScrolled" : ""}>
												{el.text}
											</NavLinks>
										) : (
											<NavLinks className={isScrolled ? "isScrolled" : ""} onClick={() => closeMobileMenu(el.to, el.id)}>
												{el.text}
											</NavLinks>
										)}
									</NavItem>
								))}
							</NavMenu>
							:
							<NavMenu show={show}>
								{data.map((el, index) => (
									<NavItem key={index} whileHover={{ scale: 1.07 }}>
										{el.isExternal ? (
											<NavLinks as="a" href={el.to} target="_blank" rel="noopener noreferrer" className={isScrolled ? "isScrolled" : ""}>
												{el.text}
											</NavLinks>
										) : (
											<NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
												{el.text}
											</NavLinks>
										)}

									</NavItem>
								))}
							</NavMenu>}




					</div>
					{loggedin == 1 ?
						<NavReg>
							<Link to="/dashboard">
								<Button>Dashboard</Button>
							</Link>
						</NavReg>
						:
						<NavReg>
							<Link to="/login">
								<NavLinks>Login</NavLinks>
							</Link>
							<Link to="/register">
								<Button>Register </Button>
							</Link>
						</NavReg>
					}
				</NavbarContainer>
			</Nav>

		</>
	);
};

export default Navbar;
