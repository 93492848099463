import React, { useState, useEffect, useRef } from "react";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
  messageVariants,
  Img1,
  Img2,
  Container,
  VisiblityPassword,
} from "./FormStyles";
import { config, setUserId, getUserId } from '../../Utils/Constants';
import Axios from "axios";
import Cookies from 'js-cookie';
import validateForm from "./validateForm";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [loaded, isLoaded] = useState(0);
  const [password, setPassword] = useState("");
  const [processsing,setProcessing] = useState(false);
  const [captcha , setCaptchaValue] = useState('');
  const [captchRefObj , setCaptchaRef] = useState(null);
  const location = useLocation();
  const bodyRef = useRef(null);
  let urlGo;

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    try {
      if (getUserId() != '')
      { 
        if(Cookies.get('url') != '')
          window.location = '/scenario_opt';
        else if(Cookies.get('pricing') != '')
          window.location = '/pricing';
        else
          window.location = '/dashboard';
      }
      else
        isLoaded(1)
    } catch (error) {
      console.log(error);
    }
    if (location.pathname === '/login' && bodyRef.current) {
      bodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  },[location]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const resultError = validateForm({
      username,
      password,
    });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    setError(null);
    if(processsing)
      return;

    setProcessing(true);
    Axios.post(config.api_url,
      {
        captcha: captcha,
        type: 'captchaverify'
      },
    ).then((res) => {
      setTimeout(function(){
          reloadCaptcha();
      },1000);
      try {
        if (res.status == "200") {
          postCaptchaVerify();
        }
        else if (res.status == "203") 
        {
          setError("Captcha verification failed");
          setProcessing(false);
        }
      } catch (e) { setError("Error in captcha validation");setProcessing(false); }
    })
      .catch(error => {
        setProcessing(false);
        setError("Captcha error");
      });
  };

  function postCaptchaVerify()
  {
    try
    {
      urlGo = Cookies.get('url');
      document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      });
    }catch(e23){}
    Axios.post(config.login_path,
      {
        user: username,
        password: password
      },
    ).then((res) => {      
      try 
      {
        if (res.status == "200") {
          loginToBackPanel(username, password);
        }
        else
        {
          setError("The username or password is not correct. please try again");
          setProcessing(false);
        }
      } catch (e) { setError("The username or password is not correct. please try again");setProcessing(false); }
    })
      .catch(error => {
        setProcessing(false);
        setError("The username or password is not correct. please try again");
      });

    setError(null);
    setSuccess("");
  }

  function onChange(value) {
    setCaptchaValue(value);
  }

  const loginToBackPanel = (username, pwd) => {
    Axios.post(config.api_url, {
      username: username,
      password: pwd,
      type: 'backPanel'
    }).then((res) => {
      try {
        if (res.data['id']) {
          setUserId(res.data['id']);
          if(urlGo != '' && urlGo !== undefined)
          {
            Cookies.set('url',urlGo);
            window.location = '/scenario_opt';
          }
          else
          {
            window.location = '/dashboard';
          }
            
        }
      } catch (e) {
      }
    }).then(() => { setProcessing(false); })    ;
  }

  const reloadCaptcha = () => {
    captchRefObj.reset();
  }
  const formData = [
    {
      label: "Usename or email",
      value: username,
      onChange: (e) => setUsername(e.target.value),
      type: "text",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      type: "password",
    },
  ];
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    loaded ?
      <FormSection>
        <Container>
          <FormRow>
            <FormColumn small>
              <FormTitle ref={bodyRef}>Welcome to Login</FormTitle>
              <Img1 src="./assets/fon.png"/>
              <Img2 src="./assets/fonhero.png"/>
              <FormWrapper onSubmit={handleSubmit} >
                {formData.map((el, index) => (
                  <FormInputRow key={index}>
                    <FormLabel style={{ color: 'white' }}>{el.label}</FormLabel>
                    <FormInput
                      type={el.type === 'password' && showPassword ? 'text' : el.type}
                      value={el.value}
                      onChange={el.onChange}
                    />
                    {el.type === 'password' && (
                      <VisiblityPassword className="password-toggle" onClick={togglePasswordVisibility}>
                        {
                          showPassword ?
                            <FontAwesomeIcon icon="fa-solid fa-eye" />
                            :
                            <FontAwesomeIcon icon="fa-solid fa-eye-slash" />
                        }

                      </VisiblityPassword>
                    )}
                  </FormInputRow>
                ))}
                <ReCAPTCHA
                      ref={el => { setCaptchaRef(el) }}
                      sitekey={config.sitekey}
                      onChange={onChange}
                />
                <Button  onClick={() => reloadCaptcha()} color="info">Reload Captcha</Button>
                <FormButton type="submit">{processsing ? "Please wait..." : "Login"}</FormButton>
                <br></br>
                <a href="/forgot" style={{ textDecoration: 'none', marginTop: 10 }}>
                  Forgot Password ?
                </a>
                <br></br><br></br>
                <a style={{ textDecoration: 'none', marginTop: 10,color:'#fff' }}>
                   Do not have an account ? 
                </a>
                <a href="/register" style={{ textDecoration: 'none', marginTop: 10 }}>
                    &nbsp;Register Now
                </a>
              </FormWrapper>
              {error && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                  error
                >
                  {error}
                </FormMessage>
              )}
              {success && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {success}
                </FormMessage>
              )}
            </FormColumn>
          </FormRow>
        </Container>
      </FormSection> :
      <></>
  );
};
export default LoginForm;