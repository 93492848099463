import styled from "styled-components";
import { motion } from "framer-motion";

export const FeatureTitle = styled.h2`
  font-size: clamp(1.5rem, 10vw, 2.9rem);
  line-height: 1.06;
  letter-spacing: 0rem;
  margin: auto;
  margin-bottom:5%;
  color: black;
  @media (max-width: 768px) {
    font-size: clamp(1.1rem, 8vw, 2.4rem);
		margin-bottom:10%;
  }
`;


export const FeatureWrapper = styled.div`
margin:0 auto;
display: flex;
flex-flow: row;
justify-content: center;
text-align:start;
width:90%;
>div{
  flex: 1;
}
@media screen and (max-width: 1100px) {
  flex-flow: column;
}

@media screen and (max-width: 568px) {
  flex-flow: column;
}
`;

export const FeatureColumn = styled(motion.div)`
background:#FAF8FE;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  margin:0 1%;  
  padding:1% 2%;
  border-radius:2rem;

`;
export const TextWrapper = styled(motion.div)`
  margin-left:10%;
  p{
  padding:0;
  padding-bottom:1%;

  }
`
export const FeatureImageWrapper = styled.div`
  background-image: url("./assets/svg/Vector.png");
  margin-bottom: 0.8rem;
  padding: 15px;
  width:100%;
  display:flex;
  align-items:center;
  @media screen and (max-width: 960px) {
    padding:0 15px;
  }
`;
export const FeatureName = styled.h3`
  font-weight: 700;
  padding: 0 15px;
  font-size: 1rem;
  letter-spacing: 1px;
  @media screen and (max-width: 768px) {
    display:none;
  }
`;
export const FeatureImgName = styled.h3`
display:none;
@media screen and (max-width: 768px) {
  display:block;
  font-weight: 400;
  padding: 0px 15px;
  font-size: 1rem;
  letter-spacing: 1.3px;
}
`
export const FeatureText = styled.p`
  text-align: start;
  font-size: 0.8rem;
  line-height: 1.43;
  color: #626881;
  padding:15px;
  font-weight: 500;


  @media screen and (max-width: 768px) {
      width:90%;
  }
`;
export const Img = styled.img`
position:absolute;
top:-10px;
right:0;
z-index:-1;
@media screen and (max-width: 768px) {
  width:40%;
}
`
