import {
  Container,
  Grid,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Alert
} from "@mui/material";
import '@fortawesome/fontawesome-free/js/all.js';
import {
  FormButton,
  messageVariants,
  FormMessage,
  Img1,
  Img2,
  FormLabel,
  FormInput
} from "../components/LoginForm/FormStyles";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { config, getUserId, getUrlVars, divStyle, paperCss, paperCssInner, file, fileLabel, paperCssInner2, downloadCurrent,Align, vg,atag } from '../Utils/Constants';
import Cookies from 'js-cookie';
const Scenario = () => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [users, setUsers] = useState("");
  const [rampup, setRampup] = useState("");
  const [dataAll, setDataAll] = useState([]);
  const [duration, setDuration] = useState("");
  const [incId, setIncomingId] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [region, setRegion] = useState('');
  const [command, setCommand] = useState('');
  const [iscopy, setCopy] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [processsing,setProcessing] = useState(false);
  const [usersallowed,setUsersAllowed] = useState(0); 
  const [showUpgrade,setShowUpgrade] = useState(0);


  //test
  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };
  useEffect(() => {
    try {

      if (getUserId() == 0)
        window.location = '/';
      try
      {
          if(Cookies.get('url'))
            setLink(Cookies.get('url'));
          Cookies.set('url','');
      }catch(e){
          console.log(e);
      }
      try 
      {
        var v = getUrlVars()['v'];
        v = parseInt(v);
        var cp = parseInt(getUrlVars()['cp']);
        if (v > 0 && !loaded) {
          setLoaded(1);
          preload(v);
          setIncomingId(v);
        }
        if (cp > 0 && !loaded) {
          setLoaded(1);
          preload(cp);
          setCopy(cp);
        }
      } catch (e2) { }

      loadPlans();

    } catch (error) {
      console.log(error);
    }
  });

  const loadPlans = () => {
    Axios.post(config.api_url, {
      uid: getUserId(),
      type: 'getplandetails'
    }).then((res) => {
      setProcessing(false);
      try {
        setUsersAllowed(res.data['maxusers']);
        setShowUpgrade(res.data['showupgrade']);
      } catch (e) { }
      
    });
  }

  const preload = (v, cp) => {
    setProcessing(true);
    Axios.post(config.api_url, {
      uid: getUserId(),
      id: v,
      cp: cp,
      type: 'getThisScenario'
    }).then((res) => {
      setProcessing(false);
      try {
        setName(res.data['name']);
        setLink(res.data['link']);
        setUsers(res.data['users']);
        setRampup(res.data['ramp_up']);
        setDuration(res.data['duration']);
        setDataAll(res.data);
      } catch (e) { }
      
    });
  }


  const saveData = () => {
    if (processsing)
      return;


    if (name === "") {
      setErrorMessage("Name cannot be blank");
      return;
    } else if (link === "" && !selectedFile) {
      setErrorMessage("Enter link or upload a file");
      return;
    } else if (!selectedFile && !/^(?:http(s)?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]+)?(\/.*)?$/.test(link)) {
      setErrorMessage("Enter a valid URL");
      return;
    } else if ((users === "" || rampup === "" || duration === "")) {
      setErrorMessage("Users, Rampup and Duration are compulsory");
      return;
    }

    setErrorMessage(null);
    setProcessing(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("users", users);
    formData.append("link", link);
    formData.append("ramp_up", rampup);
    formData.append("duration", duration);
    formData.append("uid", getUserId());
    formData.append("previd", incId);
    formData.append("selectedFile", selectedFile);
    formData.append("cp", iscopy);
    formData.append("copyfile", '/' + dataAll['orgid'] + "/jmeter/" + dataAll['jmx']);
    formData.append("type", 'saveScenario');

    Axios.post(config.api_url, formData).then((res) => {
      setProcessing(false);
      try {
        console.log(res);

        if (res.data['message'])
          setErrorMessage(res.data['message']);

        if (res.data['command'])
          setCommand(res.data['command'])

        if (res.data['success'] == '1') {
          setName('');
          setLink('');
          setUsers('');
          setRampup('');
          setDuration('');
          window.location = "/dashboard";
        }
      } catch (e) { }      
    });
  }

  const handleFileSelect = (event) => {
    setErrorMessage(null);
    if (event.target.files[0].size > 10485760) {
      setErrorMessage('You can upload only maximum of 10Mb');
    } else {
      setSelectedFile(event.target.files[0])
    }

  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  };
  const handleFileRead = (event) => {
    const fileContent = event.target.result;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(fileContent, 'text/xml');
    const threadGroupCount = xmlDoc.getElementsByTagName('ThreadGroup').length;


    if (threadGroupCount === 1) {
      const threadGroup = xmlDoc.getElementsByTagName('ThreadGroup')[0];
      const vuserCountProp = threadGroup.querySelector('stringProp[name="ThreadGroup.num_threads"]');
      const rampupProp = threadGroup.querySelector('stringProp[name="ThreadGroup.ramp_time"]');
      const durationProp = threadGroup.querySelector('stringProp[name="ThreadGroup.duration"]');
      let vuserCount = vuserCountProp ? vuserCountProp.textContent : '';
      let rampup = rampupProp ? rampupProp.textContent : '';
      let duration = durationProp ? durationProp.textContent : '';
  
      const pattern = /^\${__P\(.+?\)}$/;
  
      if (!vuserCount) {
        vuserCount = '15';
      }
      if (!rampup) {
        rampup = '50';
      }
      if (!duration) {
        duration = '3600';
      }
  
      if (
        (pattern.test(vuserCount) || !isNaN(parseInt(vuserCount))) &&
        (pattern.test(rampup) || !isNaN(parseInt(rampup))) &&
        (pattern.test(duration) || !isNaN(parseInt(duration)))
      ) {
        setSuccessMessage('File uploaded successfully!');
        setErrorMessage('');
      } else {
        setSuccessMessage('');
        setErrorMessage('Incorrect file format!');
      }
    } else {
      setSuccessMessage('');
      setErrorMessage('Incorrect file format!');
    }
  };

  return (
    <>
      <div style={divStyle}>
        <Container style={{margin:'0 45% 0 auto'}} maxWidth="sm">
          <Grid
            container
            style={{margin:'0 auto'}}
            direction="column"
            justifyContent="center"
          >
            <Paper elelvation={2} sx={paperCss} style={vg}>
              <Grid container direction="column" style={{padding:'0 8%'}}>
                <Grid item>
                  <Typography component="h4" variant="h4" style={{fontSize:'2.3rem',color:'white'}}>
                    Create New Scenario
                  </Typography>
                  <br/>
                </Grid>
                <Img1 src="./assets/fon.png"/>
      <Img2 src="./assets/fonhero.png"/>
      <br/>

                <Grid item>
                  <FormLabel >Name to remember the test</FormLabel>
                  <FormInput
                  style={{background:'white', borderRadius:'2rem', border:'1px solid #818181'}}
                    type="text"
                    fullWidth
                    variant="outlined"
                    placeholder="Name of the test"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                
                <br/>
                { (showUpgrade == 1) ? <></> : 
                <Grid item>
                  <FormControl variant="outlined" fullWidth>
                  <FormLabel >Region</FormLabel>
                    <Select
                    style={{background:'white', borderRadius:'2rem', border:'1px solid #818181', height:'40px'}}
                      labelId="region-label"
                      value={region}
                      onChange={handleRegionChange}
                    >
                      <MenuItem value="eu">EU</MenuItem>
                      <MenuItem value="ksa">KSA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> }
                <br/>
                  <Grid  item>
                    <FormLabel >Link</FormLabel>
                    <FormInput
                    style={{background:'white',border:'1px solid #818181',borderRadius:'2rem',}}
                      type="text"
                      fullWidth
                      placeholder="https://example.com, https://example2.com"
                      variant="outlined"
                      required
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </Grid>
                <Typography component="h5" variant="h5">
                  <center style={{color:'whitesmoke'}}>or</center>
                </Typography>

                <Paper elelvation={0} sx={paperCssInner}>
                  <Grid  item>
                    <label style={fileLabel}>
                      <input
                        accept=".jmx"
                        onChange={(e) => {
                          handleFileSelect(e);
                          handleFileInputChange(e);
                        }}
                        type="file"
                        name="file"
                        style={file}
                      />
                      {dataAll['isfile'] === "1"
                        ? "Click Here to replace current jmeter file"
                        : "Click Here to upload jmeter file"}
                    </label>
                    {dataAll['isfile'] === "1" && (
                      <a
                        style={downloadCurrent}
                        href={'/saas-api?file=/' + dataAll['orgid'] + '/jmeter/' + dataAll['jmx']}
                      >
                        Download current file
                      </a>
                    )}
                    {successMessage && (
                      <Alert severity="success" sx={{ marginTop: '1rem' }}>
                        {successMessage}
                      </Alert>
                    )}
                    {errorMessage && (
                      <Alert severity="error" sx={{ marginTop: '1rem' }}>
                        {errorMessage}
                      </Alert>
                    )}
                  </Grid>
                </Paper>
                <br/>
                  <Grid  item>
                  { (showUpgrade == 1) ? <><font color='#f00'>Max {usersallowed} users when executing the the test.</font><br></br><a style={atag} href='/pricing'><strong>Upgrade Now</strong></a><br></br><br></br></> : <></> }
                  <FormLabel >Users</FormLabel>
                  <FormInput
                  style={{background:'white', borderRadius:'2rem', border:'1px solid #818181'}}
                      type="number"
                      placeholder="Number of concurrent users"
                      fullWidth
                      variant="outlined"
                      required
                      value={users}
                      onChange={(e) => setUsers(e.target.value)}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                    />
                  </Grid>
                  <br></br>
                  <Grid item>
                  <FormLabel >Ramp Up</FormLabel>
                  <FormInput
                  style={{background:'white', borderRadius:'2rem', border:'1px solid #818181'}}
                      type="number"
                      fullWidth
                      placeholder="Concurrent users total rampup time (in seconds)"
                      variant="outlined"
                      required                 
                      value={rampup}
                      onChange={(e) => setRampup(e.target.value)}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                    />
                  </Grid>
                  <br></br>
                  <Grid  item>
                  <FormLabel >Duration in seconds</FormLabel>
                  <FormInput
                  style={{background:'white', borderRadius:'2rem', border:'1px solid #818181'}}
                      type="number"
                      fullWidth
                      placeholder="Total duration of the test run"
                      variant="outlined"
                      required
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                    />
                  </Grid>
                </Grid>

                <br/>
                <Grid style={{padding:'0 8%'}} item>
                  <FormButton style={{background:'#8F00BF',border:'none', color:'whitesmoke'}} onClick={(e) => { saveData(e) }}>{processsing ? "Please wait..." : "Save"}</FormButton>
                </Grid>
                <br/>
                {errorMessage && (
                  <FormMessage
                    variants={messageVariants}
                    initial="hidden"
                    animate="animate"
                    error
                  >
                    {errorMessage}
                  </FormMessage>
                )}
                {successMessage && (
                  <FormMessage
                    variants={messageVariants}
                    initial="hidden"
                    animate="animate"
                  >
                    {successMessage}
                  </FormMessage>
                )}

                <Grid item>
                  {command}
                </Grid>
            </Paper>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Scenario;