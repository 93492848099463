import styled from "styled-components";
import { motion } from "framer-motion";

export const FeatureTitle = styled.h1`
  font-size: clamp(1.5rem, 10vw, 3.5rem);
  line-height: 1.06;
  letter-spacing: 0rem;
  margin: auto;
  margin-bottom:3%;
  color: black;
  text-align:center;
  span:first-child {
    background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
	  font-size: 3rem;
    -webkit-text-fill-color: transparent;
  }
  span:last-child {
	  font-size: 3rem;
    color: white;
    font-weight:300;
  }
  `;
  
  
  export const FeatureWrapper = styled.div`
margin:0 auto;
display: flex;
flex-flow: row;
justify-content: center;
text-align:center;
width:90%;
>div{
  flex: 1;
}
@media screen and (max-width: 1100px) {
  flex-flow: column;
}

@media screen and (max-width: 568px) {
  flex-flow: column;
}
`;

export const FeatureColumn = styled(motion.div)`
background:#0E0818;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin:0 2%; 
  border-radius:2rem 0 0 0;
  border:1px solid #1E1C5E;
  padding:10px;
  font-weight:bold;
  box-shadow: 10px 10px 0px 0px rgba(30, 28, 94, 0.75);
  @media screen and (max-width: 768px) {
		margin-bottom:10%;
	}
`;

export const ValuesNum = styled(motion.h2)`
  font-size: clamp(1.5rem, 10vw, 3.4rem);
  line-height: 1.06;
  margin: auto;
  color: #9C7DF6;
  text-align:center;
  padding:10% 0 0 0;
`
export const TextWrapper = styled(motion.div)`

margin-bottom:3%;
  p{
  padding:0;
  padding-bottom:1%;

  }
`

export const FeatureText = styled.p`
  text-align: center;
  font-size: 1.0rem;
  line-height: 1.43;
  color: white;
  font-weight: 500;
    padding:0 0 10% 0;
  span{
    color:#9C7DF6;
  }


  @media screen and (max-width: 768px) {
      width:90%;
  }
`;

export const Img = styled.img`
position:absolute;
top:-4.2%;
right:0;
@media screen and (max-width: 768px) {
  width:20%;
  top:-0.67%;
}
`