import React from 'react';
import Form from '../components/LoginForm/Form';

function Login() {
	return (
		<>
			<Form />
		</>
	);
}

export default Login;
