import styled from 'styled-components';

export const PricingSection = styled.div`
	padding: 10% 0;
	display: flex;
	background: white;
	width:100%;
	@media screen and (max-width: 768px) {
		margin-top:20%;
	}
`;
export const Heading = styled.h1`
	font-size: clamp(1.3rem, 10vw, 2.5rem);
	font-weight: 600;
	color: #19191a;
	line-height: 1.06;
	letter-spacing:-3px;
	text-align: center;
	width: ${({ width }) => (width ? width : '100%')};
	@media screen and (max-width: 768px) {
	font-size: clamp(1.3rem, 10vw, 1.9rem);		
	}
`
export const PricingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	overflow-y: auto;
	scroll-behavior: smooth;
	height: 100%;
	@media screen and (max-width: 960px) {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const PricingContainer = styled.div`
	display: flex;
	justify-content: center;	
	flex-flow: wrap;	
	color:white;
	margin-top:3%;
	width:99%;
	> div {
		margin: 0.7rem;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align:start;
		width: 100%;
		> div {
			width: 90%;
		}
	}
	scroll-margin-top: 100px;
`;

export const PricingCard = styled.div`
	width:20%;
	max-height: 100%;
	flex-grow: 1;
	z-index:1;
	@media screen and (max-width: 960px) {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const PricingCardInfo = styled.div`
position:relative;
text-decoration: none;
background-image: url("./assets/svg/Vector.png");
border-radius: 2rem;
border:1px solid #D1D6F0;
height: 100%
display: flex;
flex-direction: column;
padding: 10% 5%;	
color: black;
margin: auto;
&:hover {
	transform: scale(1.02);
	transition: all 1.2s ease-out;
}

	
	@media screen and (max-width: 768px) {
		width: 100%;
		margin:8%;
		
		&:hover {
			transform: none;
		}
	}
`;
export const PricingBox = styled.div`
	width:100%;
	display: flex;
	text-align:start;
	flex-direction: column;
	// align-items: center;
	a{
		text-decoration: none;
		color: #FF0000;
		width:100%;
		display: flex;
		text-align:start;
		flex-direction: column;
		align-items: center;
	}
`
export const PricingCardPlan = styled.h3`
	font-weight: 640;
	font-size: 1.6rem;
	letter-spacing:-1.5px;
	color:black;
	padding:10px;

`;

export const PricingCardCost = styled.h4`
	font-size: 0.76rem;
	padding:10px;
	font-weight: 400;
	color:black;
`;

export const PricingCardText = styled.p`
	margin: 0.2rem 0 1rem;
`;

export const PricingCardFeatures = styled.ul`
	padding:10px;
	list-style: none;
	display: flex;
	flex-direction: column;
	color: #383945;
`;

export const PricingCardFeature = styled.li`
	margin-bottom: 1rem;
	display: flex;
	font-size: 0.74rem;
	&:before {
		// content: '✔';
		margin-right: 0.4rem;
	}
`;
export const MostButton = styled.div`
left:30%;
top:-5%;
white-space: nowrap;
padding:3% 4%;
position: absolute;
border-radius:3rem;
background:#9C7DF6;
color:white;
box-shadow: 0px 10px 20px rgba(56, 125, 255, 0.4);

`
export const PricingButton = styled.button`
	font-weight: 500;
	font-size: 0.7rem;
	text-transform: uppercase;	
	background:#353535;
	border-radius:3rem;
	color:white;
	white-space: nowrap;
	padding:3% 4%;
	outline: none;
	border:none;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	


	&:hover {
		background: ${({ inverse }) => (inverse ? '#353535' : '#353535')};
		transition: all 0.9s ease;
		color: white;
	}

`;

