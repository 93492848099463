import styled from 'styled-components';
import { Container } from '../../globalStyles';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Nav = styled.nav`
	background: transparent;
	margin-bottom: -80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: absolute;
	top: 0;
	z-index: 50;
	width: 100%;
	transition: background 0.3s ease-in;
	&.isSticky {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
	&.isScrolled {
		background: rgba(29, 15, 48, 0.914);
		transition: background 0.8s ease-in;
	}
`;

export const NavbarContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	width:85%;
	height: 80px;
	${Container}
`;

export const NavLogo = styled(Link)`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;

`;

export const NavIcon = styled.img`
	margin-right: 1rem;
	width: auto;
	height: 6vh;	
	@media screen and (max-width: 960px) {
		height:4vh;
	}
`;

export const MobileIcon = styled.div`
	display: none;
	z-index: 50;

	@media screen and (max-width: 960px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color:white;
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	width: 100%;

	@media screen and (max-width: 960px) {
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: fixed;
		padding-top: 30%;
		top: 0;
		left: 0;
		opacity: ${({ show }) => (show ? 1 : 0)};
		visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
		transform: translateY(${({ show }) => (show ? '0' : '-10px')});
		transition: opacity 0.5s ease;
		background-color: #071c2f;
	}

	> li:first-child {
		margin-left: auto;
	}

`;
export const NavReg = styled.div`
display: flex;
list-style: none;
text-align: center;
align-items: center;
z-index:50;
@media screen and (max-width: 960px) {
	display:none;
}
`

export const NavItem = styled(motion.li)`
	height: 80px;
	cursor: pointer;

	@media screen and (max-width: 960px) {
		width: 100%;

		&:hover {
			border: none;
		}
	}
`;

export const NavLinks = styled(motion.span)`
	color: #fff;
	font-weight: 600;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	font-size:1.2rem;
	&:hover {
		color: rgba(255, 255, 255, 0.8);
	}

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;

		&:hover {
			color: #5eb886;
			transition: all 0.3s ease;
		}
	}
`;

export const NavBtnLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
`;
export const Button = styled.button`
height: 3rem;
font-weight: 700;
font-size: 0.8rem;
line-height: 18px;
letter-spacing: 1.54px;
text-transform: uppercase;
cursor: pointer;
border-radius:6em;
background: white;
color:black;
white-space: nowrap;
padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
outline: none;
border: 2px solid ${({ inverse }) => (inverse ? '#636CFF' : '#636CFF')};
cursor: pointer;
`
export const Img1 = styled.img`
position:absolute;
right:0;
top:0;
z-index: -1;
width:35%;
`