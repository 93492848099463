import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormSection = styled.div`
	background: #0E0817;
	padding: 160px 0;
	position:relative;
	z-index:1;
	// width:100vw;
`;
export const Container = styled.div`
	width: 100%;
	padding: 0 5px;
	@media screen and (max-width: 960px) {
		padding: 0 30px;
	}
`;

export const FormTitle = styled.h1`
	margin-bottom: 24px;
	font-size: 40px;
	line-height: 1.1;
	font-weight: 450;
	text-align:start;
	background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const FormContainer = styled.div`
	display: flex;
`;
export const FormColumn = styled.div`
	margin:0 45% 0 auto;
	/* margin-bottom: 15px; */
	padding: 50px;
	background: transparent;
	border: 20px;
	/* padding: ${({ small }) => (small ? '0 50px' : '0 15px')}; */
	flex: 1;
	max-width: 40%;
	display: flex;
	justify-content: start;
	align-items: start;
	border-radius: 20px;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 95% !important;
	max-width: 100%;
	margin: 0 auto;
	padding:20px;


	}


`;

export const FormRow = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
`;

export const FormWrapper = styled.form`
	/* max-width: 540px; */
	padding-top: 0;
	width: 100%;
	a{
		color:#7C78F0;
	}
	p{
		color:white;
		text-align:center;
		padding-top:5%;
	}
	h6{
		padding:4% 0;
		font-size:0.75rem;
		letter-spacing:-0.2px;
		color:white;
		font-weight:300;
	}
`;
export const FormDiv=styled.div`
display:flex;
justify-content:center;
`
export const FormMessage = styled(motion.div)`
	color: ${({ error }) => (error ? 'red' : 'green')};
	padding: 5px;
	text-align: center;
	margin-top: 1rem;
`;

export const FormInputRow = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;

	> p {
		font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
`;
export const FormInput = styled.input`
	display: block;
	padding-left: 10px;
	outline: none;
	height: 40px;
	width: 100%;
	border: none;
	border-bottom: 1px solid #818181;
	font-size: 1rem;
	background:white;
	border-radius:2rem;
`;

export const FormLabel = styled.label`
	display: inline-block;
	font-size: 0.9rem;
	margin-bottom: 0.3rem;
	color: #afafaf;
`;
export const FormImgWrapper = styled.div`
	max-width: 555px;
	display: flex;
	justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;
export const FormImg = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	max-height: 500px;
`;

export const FormButton = styled.button`
	border-radius: 4px;
	background: #8F00BF;
	margin-top: 1.5rem;
	white-space: nowrap;
	border-radius:2rem;
	/* color: #fff; */
	outline: none;
	width: 100%;
	font-size: 1.4rem;
	padding: 5px 15px;
	border: 1px solid #818181;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	color: white;
	display:flex;
	justify-content:center;
	align-items:center;
	img{
		margin-right:15px;
		width:6%;
	}
	h5{
		padding:1% 0;
		color:white;
	}
	@media screen and (max-width: 768px) {
		img{
			width:11%;
		}
	}
	`;
	
export const VisiblityPassword = styled.div`
	position:absolute;
	left:49%;	
	color:#8F00BF;
	z-index:50;
	cursor:pointer;
	padding-top:1.6%;
@media screen and (max-width: 768px) {
	padding-top:5.5%;
	position:absolute;
	left: 75%;
}
`
export const Img1 = styled.img`
	position:absolute;
	right:0;
	top:0;
	z-index: -1;
	width:40%;
	@media screen and (max-width: 960px) {
		width:75%;
	}
	`
export const Img2 = styled.img`
	position:absolute;
	left:0;
	bottom:0;
	z-index: -1;
	width:25%;
	@media screen and (max-width: 960px) {
	width:55%;
		
	}
	`;


export const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
};
