import styled from "styled-components";
export const ContactSection = styled.div`
	padding: 10% 0;
	display: flex;
	background: white;
	width:100%;
	@media screen and (max-width: 768px) {
		margin-top:20%;
	}
`;

export const ContactWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	@media screen and (max-width: 960px) {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const ContactContainer = styled.div`
	width:100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	color:white;
	margin-top:3%;
	> div {
		margin: 0.7rem;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		text-align:center;
		width: 100%;
		> div {
			width: 90%;
			height:20%;
		}
		margin-bottom:10%;
	}
`;



export const ContactCardInfo = styled.div`
position:relative;
text-decoration: none;
background-image: url("./assets/svg/Vector.png");
height: 100%;
width:100%;
display: flex;
flex-direction: column;
color: black;
&:hover {
	transform: scale(1.02);
	transition: all 1.2s ease-out;
}

	
@media screen and (max-width: 768px) {
		width: 100%;
		margin:8%;
		
		&:hover {
			transform: none;
		}
	}
`;
export const ContactBox = styled.div`
	width:100%;
	display: flex;
    flex:1;
	flex-direction: column;
    padding:2% 1%;
    margin:10px;
`
export const ContactTitle = styled.h3`
	font-weight: 600;
	font-size: 1rem;
	color:black;
	text-align:start;
	padding-left:10px;

`;
export const Heading1 = styled.h2`	
	padding:10px;
	font-size: 1.9rem;
	font-weight: 600;
	color: black;
	text-align:start !important;

	@media screen and (max-width: 768px) {
		text-align: start;
		font-size: 1.9rem;
		line-height: 1.1;
	}
`;

export const ContactInfo = styled.h4`
	font-size: 0.76rem;
	padding-left:10px;
	font-weight: 400;
	color:black;
    text-align:start;
	
`;
export const ContactText = styled.h4`
	font-size: 1.16rem;
	padding:10px;
	font-weight: 400;
	color:black;
    text-align:start;
`
export const ImgIcons = styled.img`
 	width:45%;
	margin: 0 auto;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	z-index: 50;
	padding:1.5%;
	@media screen and (max-width: 768px) {
		width:60%;
	}
`;
export const InputWrapper= styled.div`
width:85%;
display: flex;
position:relative;
flex-direction: column;
justify-contect:center;
text-align:center;
margin: 0 auto 10% auto;
border:1px solid #D1D6F0;
padding:2%;
border-radius:3rem;
padding-bottom:6%;
textarea {
	width:80%;
	background:blue;
}
p{
	text-align:start;
	padding-left:10%;
	color:red;
}
@media screen and (max-width: 960px) {
padding-bottom:16%;
margin: 0 30px;
textarea {
	margin-top:14px;
	width:75%;
    }
	
	margin-bottom:20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}`
export const FormInputRow=styled.div`
width:80%;
margin:0 auto;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 2rem;
margin-bottom:3%;
@media screen and (max-width: 960px) {
	grid-template-columns: repeat(1, 2fr);
	grid-gap: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
`;
export const InputTitle= styled.h2`
font-size: 2.76rem;
padding:10px;
font-weight: 500;
color:black;
text-align:center;
@media screen and (max-width: 960px) {
font-size: 2.2rem;
	
	}
`
export const FormInput=styled.input`
padding-left:19px;
padding-right:10px;
background:white;
outline: none;
border: 1px solid #9A9A9A; 
border-radius: 2rem;
height: 50px;
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder{
	color:black;
}
@media screen and (max-width: 960px) {
width:250px;
}`

export const Button = styled.button`
border-radius: 3rem;
background: #8F00BF;
white-space: nowrap;
padding: 10px 20px;
font-size: 1.2rem;
color: #fff;
outline: none;
border: 2px solid #fff;
cursor: pointer;
overflow: hidden;
position: absolute;
bottom:3%;
left:12%;
alignItems: start;
@media screen and (max-width: 960px) {
	bottom:1%;
	left:12%;
}`
export const EmailButton = styled.button`
border-radius: 3rem;
background: #8F00BF;
white-space: nowrap;
padding: 5px 10px;
font-size: 1rem;
color: #fff;
outline: none;
border: 2px solid #fff;
cursor: pointer;
overflow: hidden;
margin-right:5%;
a{
color:white;
text-decoration:none
}
@media screen and (max-width: 960px) {
	bottom:1%;
	left:12%;
}`

export const StyledReCAPTCHA = styled.div`
padding: 14px;
margin-left:8.5%;
div{
	width:10%;
}
@media screen and (max-width: 960px) {
margin-left:0%;
}
`;

