import styled from "styled-components";
import { motion } from "framer-motion";

export const FeatureTitle = styled.h2`
  text-align: center;
  font-size: clamp(1.1rem, 12vw, 3rem);
  line-height: 1.06;
  letter-spacing: 0.3rem;
  margin: auto;
  color: black;
  @media screen and (max-width: 768px) {
    text-align:start;
    font-size:2.5rem;
	}
`;

export const FeatureTextWrapper = styled.div`
  position: relative;
  padding: 0 0 20px;
  margin-bottom: 4rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 0rem;

	}

`;
export const FeatureContainer =styled.div`
display:flex;
flex-direction:row;
justify-content:space-around;
align-items:center;
@media screen and (max-width: 768px) {
  display:flex;
flex-direction:column;
justify-content:space-around;
align-items:center;
}
`
export const FeatureWrapper = styled.div`
display:grid;
padding:10%;
grid-template-columns: repeat(2, 1fr);
  margin-top: 4rem;
  grid-gap: 2rem;
  text-align:center;

  @media screen and (max-width: 1100px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 3rem;
  margin-top: 1rem;

  }

  @media screen and (max-width: 568px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FeatureColumn = styled(motion.div)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  padding: 5px;
`;

export const FeatureImageWrapper = styled.div`
  margin-bottom: 1rem;
  // padding: 30px;
`;
export const FeatureName = styled.h3`
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 2px;
  text-align: start; 
  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 1.3px;
  }
`;
export const FeatureText = styled.p`
  margin: 1rem 0 auto;
  text-align: start;
  font-size: 0.9rem;
  line-height: 1.73;
  letter-spacing: 0.5px;
  color: #626881;

  span {
    display:block;
    font-size: 1.2rem;
    weigh:600;
    text-align: center;
    color: #86838B;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
