export default function validateForm({
    username,
    password
  }) {
    if (!username.trim()) {
      return "Username required";
    }
    // else if (!/^[A-Za-z]+/.test(name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
  
    if (!password) {
      return "Password is required";
    } else if (password.length < 5) {
      return "Password needs to be 5 characters at least";
    }
  
    return null;
  }
  