import React, { useEffect } from 'react';
import { ContactButton, ContactWrapper, Img, ImgWrap, MainText, Section, Textwrap, TopText } from './ContactStyles';
import {Link} from "react-router-dom"
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
export const Contact = () => {
    const initial = { opacity: 0, y: 50 };
	const animation = useAnimation();
	const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(()=>{
        if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
    }, [inView, animation])
    return (
            <Section ref={ref}>
                <ContactWrapper>
                    <Textwrap>
                        <TopText>GET IN TOUCH</TopText>
                        <MainText
                            initial={initial}
                            transition={{ delay: 0.3, duration: 0.6 }}
                            animate={animation}
                        >
                            Worked with 25+ firms for performance testing solutions.
                        </MainText>
                        <ContactButton><Link style={{textDecoration:'none'}} to="/contact">Contact us</Link></ContactButton>
                    </Textwrap>
                    <ImgWrap>
                        <Img src='./assets/svg/contact.webp'/>
                    </ImgWrap>
                </ContactWrapper>
            </Section>
    );
}
export default Contact;
