import base64 from 'react-native-base64';
import Axios from "axios";
import Cookies from 'js-cookie'
export const config = {
						site_url	: 'https://crewload.crewformance.com/',
						api_url		: 'https://crewload.crewformance.com/saas-api/',
						grafana_url	: 'http://34.254.255.99:3000/',
						grafana_url_ip : 'crewload.crewformance.com/grafana/grafana/',
						grafana_masked : 'crewload.crewformance.com/grafana/',
						url_auth_keys:'/api/auth/keys',
						basic_auth : 'YWRtaW46dGVzdGNyZXc=',
						login_path : '/grafana/login',
						grafana_login : '/app/dashboard/login',
						grafana_login_new : 'login',
						email_js_serviceid:'service_67jrhh6',
						email_js_templateid: 'template_uv2c4rc',
						email_js_key:'EpMCle3Y5I176_ZbR',
						password_reset:'passwordreset?data=', 
						enc_key:'4mnMl0Er2RfNYKm0MzY7xgS4zKss59ng14lOpB84OwJkS6wB66',
						reset_pwd: '/api/admin/users/{UID}/password',
						sitekey:'6Lf-OQAqAAAAAAO60dKbgc0wOMmRe_YZZk8BUOhI'

					   };
	  
export function isNumeric(n) {
  		return !isNaN(parseFloat(n)) && isFinite(n);
}

export function setUserId(id)
{
	Cookies.set('userid', id,{ expires: (1/12) });
}

export function getUserId()
{
	try
	{
		var id 		= (Cookies.get('userid') == null) ? "" : Cookies.get('userid');
		var isNum	= isNumeric(id);
		if(id != "" && id != '0')
		{
			return id;
		}
			
		else
			return '';
	}catch(e){}
	return '';
}


export function  getUrlVars() 
{
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
    function(m,key,value) {
      vars[key] = value;
    });
    return vars;
}

export function decodePassword(password)
{
	return base64.decode(password);

}

export function convertToNormalUrl(url)
{
	try{url =  url.replace(config.grafana_url_ip,config.grafana_masked);}catch(e){}
	return (url == null) ? "" : url;
}

export const atag = {textDecoration:'none',color:'#636CFF'}
export const divStyle ={background:'#0E0817',position:'relative',zIndex:'1'};
export const Width = {width:'100%', overflow:'hidden'};
export const paperCss = { padding: "4%",borderRadius: '20px' , marginTop : 20, marginBottom:10 };
export const paperCssInner = {padding: "8%" , marginTop : '10px',zIndex:1,background:'#0E0817' };
export const paperCssInner2 = { padding: "8%" ,background:'#160B25', borderRadius: '0px',borderColor:'#0E0817' ,marginTop : 20 };
export const file = {display:'none',width:'100%',height:20,marginTop:20}
export const downloadCurrent = {textDecoration:'none',marginTop:20, borderRadius: '5px',height:40,textAlign:'center',display:'block',cursor:'pointer',width:'100%',color:'#fff' , background:'#0E0817',padding:'10px 20px' }
export const fileLabel = {fontSize:'0.9rem',fontWeight:'700',height:40,textAlign:'center',display:'block',width:'100%',color:'black' ,cursor:'pointer',borderRadius:'2rem', background:'white',padding:'5px 20px' }
export const vg = {color:'#9156F1',background:'#160B25'}