import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Section =styled.div`
background: linear-gradient(#3A08BC, #6701D6, #960FAD);
`

export const ContactWrapper = styled.div`
max-width: 1300px;
margin-right: 0;
margin-left: auto;
padding: 0 5px;
object-fit: cover;
display: flex;
justifyContent: center;
align-items:end;
@media screen and (max-width: 960px) {

    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    
}
`

export const Textwrap=styled.div`
display:flex;
flex-direction:column;
justify-content:end;
align-items:start;
margin:0 10%;

`

export const ImgWrap=styled.div`
width:50%;
object-fit: cover;
@media screen and (max-width: 960px) {
display:none;
}
`
export const TopText=styled.p`
font-size:0.7rem;
font-weight: 600;
padding-top:15%;
line-height: 24px;
text-align: center;
color: #9C7DF6;
`
export const MainText=styled(motion.h2)`
    margin-top:2%;
	margin-bottom: 24px;
	font-size: 2.5rem;
    color:white;
	font-weight: 600;
    letter-spacing:-1px;
    z-index:1;
    span:first-child {
    background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span:last-child {
    color: white;
  }
	@media screen and (max-width: 768px) {
		font-size: 1.8rem;
		line-height: 1.1;

	}
`

export const ContactButton = styled.button`
background:white;
border-radius:3rem;
font-weight: 800;
color:black;
white-space: nowrap;
padding:2% 3%;
margin-bottom:14%;
outline: none;
border:none;
cursor: pointer;
position: relative;
overflow: hidden;
`

export const Img = styled.img`
width:100%;
`