export default function validateForm({
  Fname,
  name,
  org,
  email,
  password,
  confirmPass,
}) {
  if (!Fname.trim()) {
    return "Full Name required";
  }
  if (!name.trim()) {
    return "Username required";
  }
  // else if (!/^[A-Za-z]+/.test(name.trim())) {
  //    errors.name = 'Enter a valid name';
  // }

  if (!org.trim()) {
    return "Organization required";
  }

  if (!email) {
    return "Email required";
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return "Enter a valid email";
  }
  if (!password) {
    return "Password is required";
  } else if (password.length < 6) {
    return "Password needs to be 6 characters at least";
  } else if (!confirmPass) {
    return "Password is required";
  } else if (password !== confirmPass) {
    return "passwords not matching";
  }
  return null;
}
