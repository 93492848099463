import React, { useEffect } from 'react';
import { Container, Section } from '../../globalStyles';
import {
	FeatureText,
	FeatureWrapper,
	FeatureColumn,
	FeatureImageWrapper,
	FeatureName,
	Heading,
	TextWrapper,
	FeatureTitle,
	Img,
	FeatureImgName,
} from './FeaturesStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

const Features = () => {
	const initial = { opacity: 0, y: 50 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);

	return (
		<Section smPadding="50px 10px" position="relative" inverse id="" ref={ref}>
			<Container>
				<TextWrapper>
					<FeatureText>FOCUS AREAS</FeatureText>
					<FeatureTitle>What can performance do</FeatureTitle>
					<Img src='./assets/underhero.png' />
				</TextWrapper>
				<FeatureWrapper>
					<FeatureColumn
						initial={initial}
						transition={{ delay: 0.3, duration: 0.6 }}
						animate={animation}
					>
						<FeatureImageWrapper className>
							<FontAwesomeIcon icon="fa-solid fa-shield-halved" size="3x" color='#FF6BF0' />
							<FeatureImgName>Web App <br />Testing</FeatureImgName>
						</FeatureImageWrapper>
						<FeatureName>Web App <br />Testing</FeatureName>
						<FeatureText>Efficiently test your website, identify critical limitations, and prevent crashes during crucial times. Instantly load test your solution and obtain direct results for effective optimization.</FeatureText>
					</FeatureColumn>
					<FeatureColumn
						initial={initial}
						transition={{ delay: 0.3, duration: 0.6 }}
						animate={animation}
					>
						<FeatureImageWrapper className>
							<FontAwesomeIcon icon="fa-solid fa-globe" size="3x" color='#FF6BF0' />
						<FeatureImgName>API Services <br /> Testing</FeatureImgName>
						</FeatureImageWrapper>
						<FeatureName>API Services <br /> Testing</FeatureName>
						<FeatureText>Ensure your APIs scale up
							under large number of
							requests. Mobile, web
							app, game, IOT, smart car,
							whatever the type of end
							point is, you can trust
							CrewLoad.</FeatureText>
					</FeatureColumn>
					<FeatureColumn
						initial={initial}
						transition={{ delay: 0.3, duration: 0.6 }}
						animate={animation}
					>
						<FeatureImageWrapper className>
							<FontAwesomeIcon icon="fa-solid fa-wand-magic-sparkles" size="3x" color='#FF6BF0' />
						<FeatureImgName>Simulate Heavy Trafficg</FeatureImgName>
						</FeatureImageWrapper>
						<FeatureName>Simulate Heavy Traffic</FeatureName>
						<FeatureText>Using CrewLoad, you can
							simulate up to millions of
							virtual people navigating on
							your app, which helps you
							analyze your application’s
							performance precisely and
							identify its bottlenecks.</FeatureText>
					</FeatureColumn>
					<FeatureColumn
						initial={initial}
						transition={{ delay: 0.3, duration: 0.6 }}
						animate={animation}
					>
						<FeatureImageWrapper className>
							<FontAwesomeIcon icon="fa-solid fa-chart-column" size="3x" color='#FF6BF0' />
							<FeatureImgName>Customizable Real Time Reporting</FeatureImgName>
						</FeatureImageWrapper>
						<FeatureName>Customizable Real Time Reporting</FeatureName>
						<FeatureText>Easily generate custom report visualizations, compare load results over time, and create report templates with just a few clicks, streamlining the entire process effortlessly.</FeatureText>
					</FeatureColumn>
				</FeatureWrapper>
			</Container>
		</Section>
	);
};

export default Features;
