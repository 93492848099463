import React, { useEffect, useState } from 'react';
import { HeroPart, HeroSection,  Img1, Container, HeroText  } from '../About/AboutStyles';
import { Section } from '../../globalStyles';
import { Box, BoxGroup, BoxText,Img, Imgfon } from './LinkCaseStyles';
import {  Heading} from '../Hero/HeroStyles';
import axios from 'axios';

const LinkCase = () => {
    const [users, setUsers] = useState([]);

    useEffect(()=>{
      axios.get('https://641d854f4366dd7def3fd2b0.mockapi.io/g/users')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
          console.error(error);
      });
    },[])
    return (
        <div>
            <HeroSection >
                <Container>
                    <HeroPart >
                        <Img1 src="./assets/fonabout.png" />
                        <Heading><span>Case study</span></Heading>
                        <HeroText>
                        Discover how our solutions transformed performance monitoring, delivering exceptional value and tangible results for our clients.
                        </HeroText>
                    </HeroPart>
                    <HeroPart>
                    <div style={{border:'3px solid #100C34', borderRadius:'50%'}}>
                        <Imgfon src="./assets/casefon.png" alt="menu" />
                        </div>
                    </HeroPart>
                </Container>
            </HeroSection>
            <Section>
                 <Box>
                 {users.map((el, index) => (
                    <BoxGroup key={index}>
                        <Img src={el.avatar}/>
                        <BoxText>{el.name}</BoxText>
                        <p>{el.info}</p>
                    </BoxGroup>
                 ))}
                 </Box>
            </Section>
        </div>
    );
}

export default LinkCase;
