import React from 'react';
import { Container, Section } from '../../globalStyles';
// import { FeatureText, FeatureTitle } from '../Features/FeaturesStyles';
import { FeatureName } from '../Why/WhyStyles';
import { ColumnWrapper, Icon, Img, InfoWrapper, TextWrapper, FeatureText, FeatureTitle } from './CaseStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {Link} from "react-router-dom"
const Case = () => {
    return (
        <Section>
            <Container>
                <TextWrapper>
                    <span>
                        <FeatureText>READ ABOUT Crew Formance</FeatureText>
                        <FeatureTitle>Case studies</FeatureTitle>
                    </span>
                </TextWrapper>
                <ColumnWrapper>
                    <InfoWrapper style={{ borderRadius: '2rem 2rem 0 0' }}>
                        <Icon><Link to='/case'><FontAwesomeIcon size='2x' icon="fa-solid fa-arrow-right" /></Link></Icon>
                        <TextWrapper>
                            <FeatureName>Expertise and Experience</FeatureName>
                            <FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
                        </TextWrapper>
                        <Img src='./assets/svg/base.png' />
                    </InfoWrapper>


                    <InfoWrapper>
                        <Icon><Link to='/case'><FontAwesomeIcon size='2x' icon="fa-solid fa-arrow-right" /></Link></Icon>
                        <TextWrapper>
                            <FeatureName>Expertise and Experience</FeatureName>
                            <FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
                        </TextWrapper>
                        <Img width='222px' src='./assets/sport.webp' />
                    </InfoWrapper>



                    <InfoWrapper>
                        <Icon><Link to='/case'><FontAwesomeIcon size='2x' icon="fa-solid fa-arrow-right" /></Link></Icon>
                        <TextWrapper>
                            <FeatureName>Expertise and Experience</FeatureName>
                            <FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
                        </TextWrapper>
                        <Img width='222px' src='./assets/gover.webp' />
                    </InfoWrapper>
                </ColumnWrapper>

            </Container>
        </Section>
    );
}

export default Case;
