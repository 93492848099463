import React, { useEffect } from 'react';
import { Container, Section } from '../../globalStyles';
import {
	FeatureText,
	FeatureTitle,
	FeatureWrapper,
	FeatureColumn,
	FeatureImageWrapper,
	FeatureName,
	FeatureTextWrapper,
	FeatureContainer,
} from './WhyStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { Img, ImgWrapper } from '../Content/ContentStyles';
import { HeroPart } from '../Hero/HeroStyles';

const Features = () => {
	const initial = { opacity: 0, y: 50 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);

	return (
		<Section smPadding="50px 10px" position="relative" inverse id="" ref={ref}>
			<Container>
				<FeatureTextWrapper>
					<FeatureText><span>Testing Highlights</span></FeatureText>
					<FeatureTitle>Deliver performance at scale</FeatureTitle>
				</FeatureTextWrapper>
				<FeatureContainer>
					<HeroPart>
					<FeatureWrapper>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-solid fa-atom" size="3x" color='#636CFF' />

							</FeatureImageWrapper>
							<FeatureName>Expertise and Experience</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-solid fa-display" size="3x" color='#636CFF' />
							</FeatureImageWrapper>
							<FeatureName>Ensuring Product Excellence</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-solid fa-shapes" size="3x" color='#636CFF' />
							</FeatureImageWrapper>
							<FeatureName>Tailored Approach</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-brands fa-bootstrap" size="3x" color='#636CFF' />

							</FeatureImageWrapper>
							<FeatureName>Reliability and Trust</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-solid fa-mug-hot" size="3x" color='#636CFF' />

							</FeatureImageWrapper>
							<FeatureName>Comprehensive Software Testing</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
						<FeatureColumn
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							<FeatureImageWrapper className>
								<FontAwesomeIcon icon="fa-solid fa-globe" size="3x" color='#636CFF' />

							</FeatureImageWrapper>
							<FeatureName>Comprehensive Software Testing</FeatureName>
							<FeatureText>We provide a wide range of software testing services to ensure the quality and reliability of your product.</FeatureText>
						</FeatureColumn>
					</FeatureWrapper>
					</HeroPart>
					<HeroPart>
					<ImgWrapper>
						<Img
							src="./assets/why.webp"
							alt={'Why photo'}
							whileHover={{ rotate: 2, scale: 1.02 }}
							transition={{ duration: 0.5 }}
						/>
					</ImgWrapper>

					</HeroPart>
				</FeatureContainer>
			</Container>
		</Section>
	);
};

export default Features;
