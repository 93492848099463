// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon{
    margin: 0 auto;
    color:rgb(11, 160, 11);
    font-size: 10rem;
    --fa-animation-duration: 6.5s;
    padding: 8%;
}
.boxstyle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height:350px;
    background-color: #0E0817;
    border: 2px solid inherit;
    border-radius: 2rem;
    color:#F76BF0;
    text-align:center;
    @media screen and (max-width: 768px) {
		max-width: 85%;
	}

}
`, "",{"version":3,"sources":["webpack://./src/components/RegistrationForm/Icon.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sBAAsB;IACtB,gBAAgB;IAChB,6BAA6B;IAC7B,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB;EACF,cAAc;CACf;;AAED","sourcesContent":[".icon{\n    margin: 0 auto;\n    color:rgb(11, 160, 11);\n    font-size: 10rem;\n    --fa-animation-duration: 6.5s;\n    padding: 8%;\n}\n.boxstyle{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 500px;\n    height:350px;\n    background-color: #0E0817;\n    border: 2px solid inherit;\n    border-radius: 2rem;\n    color:#F76BF0;\n    text-align:center;\n    @media screen and (max-width: 768px) {\n\t\tmax-width: 85%;\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
