import {
  Container,
  Button,
  Grid,
  Paper,
  Pagination,
  Tooltip,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import DataTable,{ createTheme } from 'react-data-table-component';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, Text, useMemo } from "react";
import Axios from "axios";
import { config, convertToNormalUrl, getUserId, divStyle, paperCss, vg } from '../Utils/Constants';
import { Img1, Img2 } from "../components/LoginForm/FormStyles";
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie'

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#925ae6",
      border:'1px solid #c1a0f7'
    }
  }
}));
createTheme('solarized', {
  text: {
    primary: '#c1a0f7',
    secondary: 'white'
  },
  background: {
    default: '#160B25',
  },
  context: {
    background: '#352c47',
    text: '#352c47',
  },
  divider: {
    default: 'transparent',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');
const customStyles = {
  rows: {
    style: {
      minHeight: '52px',
      borderRadius: '3rem',
      marginBottom: '10px',
      background: '#1E1034',
      color: 'white'
    }
  },
  headCells: {
    style: {
      paddingLeft: '2%',
      paddingRight: '8px',

    },
  },
  cells: {
    style: {
      paddingLeft: '2%',
      paddingRight: '8px',

    },
  },
};

const Dashboard = () => {
  const [loaded, isLoaded] = useState(0);
  const [cronloaded, setCronLoaded] = useState(0);
  const [reloadMemo, setMemoReload] = useState((new Date()).getTime());
  const [dataList, setData] = useState([]);
  const [uid, setUserId] = useState(0);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [progressPercent,setProgressPercent] = useState(0);
  const [currentrow,setCurrentRow] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState('Intializing');
  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageLength: 5,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  var tempPlayListArray = {};
  var tabPlay;

  useEffect(() => {
    if (loaded == 0) {
      if (getUserId() == 0)
        window.location = '/';
      else {
        isLoaded(1)
        setUserId(getUserId());
      }
    }
    else if (cronloaded == 0) {
      setCronLoaded(1);
      cron();
    }
    Cookies.set('url','',{ expires: 1 });

  }, [dataList]);

  const loadData = () => {
    if (processing)
      return;
    setProcessing(1);
    const { page, pageLength } = paginationModel;
    Axios.post(config.api_url, { "type": "getScenario", "uid": uid, pagelength: pageLength, page: page - 1 }).
      then((res) => {
        if (res.data['success'] == "1") {
          setData(res.data.data);
          setTotalCount(parseInt(res.data.totalcount));
        }
      });
  }

  const edit = (row) => {
    window.location = '/scenario_opt?v=' + row['id'];
  }

  const deleteEntry = (row) => {
    if (window.confirm("Delete this entry ? ")) {
      Axios.post(config.api_url, {
        uid: getUserId(),
        id: row['id'],
        type: 'deleteThisScenario'
      }).then((res) => {
        try{ if(res.data['message']) alert(res.data['message']);}catch(e){}
        try {
          if (res.data['success'] == "1") {
            setProcessing(0);
            loadData();
          }
        } catch (e) { }

      });


    }
  }

  const history = (row) => {
    window.location = '/history?reference=' + row['id'];

  }

  const handlePageChange = (event, page) => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: page

    }));
    setProcessing(0)
    loadData()
  };
  const handleRowsPerPageChange = (event) => {
    const rowsPerPage = parseInt(event.target.value);
    setRowsPerPage(rowsPerPage);
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      pageLength: rowsPerPage,
      page: 1,
    }));
    setProcessing(0);
  };
  const totalPages = Math.ceil(totalCount / paginationModel.pageLength);
  const currentPage = paginationModel.page;


  const cron = () => {
    tempPlayListArray = dataList;
    Axios.post(config.api_url, {
      uid: getUserId(),
      type: 'checkScenario',

    }).then((res) => {
      if (res.data['success'] == "1") {
        if (res.data['list'].length > 0) {
          for (var i = 0; i < tempPlayListArray.length; i++) {
            var row = tempPlayListArray[i];
            for (var j = 0; j < res.data['list'].length; j++) {
              if (row['id'] == res.data['list'][j]) {
                row['running'] = "0";
                tempPlayListArray[i] = row;
              }
            }
          }
          setData(tempPlayListArray);
          setMemoReload((new Date()).getTime());
        }
      }
      setTimeout(function () { cron() }, 15000);
    });
  }
  const copy = (row) => {
    window.location = '/scenario_opt?cp=' + row['id'];
  }

  const cronLoaded = (toUrl,id,index) => {
    tempPlayListArray = dataList;
    Axios.post(config.api_url, {
      uid: getUserId(),
      id:id,
      type: 'getstarted',

    }).then((res) => {
      if(index < 90)
      {
        setProgressPercent(index); 
        if(index < 10)
          setStatusUpdate("Intializing");
        else if(index > 10 && index < 20)
          setStatusUpdate("Preparing all necessary files");
        else if(index > 20 && index < 30)
          setStatusUpdate("Uploading the necessary files");
        else if(index > 30 && index < 50)
          setStatusUpdate("Calculating the Hardware requirements");
        else if(index > 50 && index < 70)
          setStatusUpdate( "Assembling the Hardwares and Files");
        else if(index > 70 && index < 80)
          setStatusUpdate("Preparing all the necessary parameters");
        else if(index > 80 && index < 90)
          setStatusUpdate("All stuffs are been placed in order to start the test");
        else if(index > 91)
          setStatusUpdate("Test will start in a few moment");
        if (res.data['ready'] == "1") {
            setLoadingDiv(false);
            window.open(toUrl,'_blank');
        }
        else
          setTimeout(function () { cronLoaded(toUrl,id,(index+2)) }, 1400);
      }
      else
      {
          setLoadingDiv(false);
          window.open(toUrl,'_blank');
      }
    });
  }

  const _play = (row) => {
    setCurrentRow(row);
    tempPlayListArray = dataList;
    if (convertToNormalUrl(row['grafana_url']) != "") {
      if (row['running'] == "0") {
        var toUrl = convertToNormalUrl(row['grafana_url']);
        Axios.post(config.api_url, {
          uid: getUserId(),
          id: row['id'],
          url: toUrl,
          type: 'executeScenario'
        }).then((res) => {
          try {
            if (res.data['success'] == "1") {
              setLoadingDiv(true);
              tempPlayListArray[row.aindex]['running'] = 1;
              setData(tempPlayListArray);
              setMemoReload((new Date()).getTime());
              cronLoaded(toUrl,row['id'],0);
            }
            else
              alert(res.data['message']);
          } catch (e) { }
        });
      }
      else {
        tempPlayListArray[row.aindex]['running'] = 1;
        setData(tempPlayListArray);
        alert("Already executing this scenario");
      }
    }
    else
      alert("There was problem in creating this test scenario");
  }

  function confirmDialog(row,type)
  {
      if(type == 1)
      {
          if(window.confirm('Are you sure to cancel the test ?'))
          {
              setLoadingDiv(false);
              stop(row);
          }
      }
      else
        setLoadingDiv(false);
      
  }

  const stop = (row) => {
    tempPlayListArray = dataList;
    if (row['grafana_url'] != "") {
      if (row['running'] == "1") {
        Axios.post(config.api_url, {
          uid: getUserId(),
          id: row['id'],
          type: 'stopThisScenario'
        }).then((res) => {
          try {
            if (res.data['success'] == "1") {
              tempPlayListArray[row.aindex]['running'] = 0;
              setData(tempPlayListArray);
              setMemoReload((new Date()).getTime());
              tabPlay.close();
            }
            else
              alert(res.data['message']);
          } catch (e) { }
        });
      }
      else {
        tempPlayListArray[row.aindex]['running'] = 0;
        setData(tempPlayListArray);
        alert("Already scenario stopped");
      }
    }
  }

  const columns = useMemo(() => [{ name: '#', selector: row => row.index, sortable: true, width: "20pt", }, { name: 'Name', selector: row => row.name, sortable: true, width: "120pt", }, { name: 'Users', selector: row => row.users, sortable: true, width: "75pt", }, { name: 'Ramp Up', selector: row => row.ramp_up, sortable: true, width: "80pt", }, { name: 'Duration', selector: row => row.duration, sortable: true, width: "80pt", }, { name: 'Created On', selector: row => row.dated, sortable: true, width: "120pt", },
  {
    cell: row => (<> {(row['running'] == "1") ?
      <><Tooltip title='Stop'><Button variant="outlined" onClick={() => confirmDialog(row,1)} color="error"><FontAwesomeIcon icon="fa-stop" /></Button></Tooltip></> :
      <><Tooltip title='Start'><Button variant="outlined" onClick={() => _play(row)} color="success"><FontAwesomeIcon icon="fa-play" /></Button></Tooltip></>}
      &nbsp;&nbsp;&nbsp;
      <Tooltip title='Edit'><Button variant="outlined" onClick={() => edit(row)} color="info"><FontAwesomeIcon icon="fa-pen" /></Button></Tooltip>
      &nbsp;&nbsp;&nbsp;
      <Tooltip title='History'><Button variant="outlined" onClick={() => history(row)} color="info"><FontAwesomeIcon icon="fa-list" /></Button></Tooltip>
      &nbsp;&nbsp;&nbsp;
      <Tooltip title='Copy This'><Button variant="outlined" onClick={() => copy(row)} color="warning"><FontAwesomeIcon icon="fa-file" /></Button></Tooltip>
      &nbsp;&nbsp;&nbsp;
      <Tooltip title='Delete'><Button variant="outlined" onClick={() => deleteEntry(row)} color="error"><FontAwesomeIcon icon="fa-trash" /></Button></Tooltip></>),
    width: "120pt",

  }], [reloadMemo, dataList]);
  const addNew = () => {
    window.location = '/scenario_opt';
  }

  const classes = useStyles();
  return (
    <>
      {loaded ? (<>
        <div onLoad={loadData()} style={divStyle} >
          <Container>
            <Grid
              style={{ margin: '0 auto' }}
              container
              direction="column"
              justifyContent="center"
            >

              <Paper sx={paperCss} style={{ maxWidth: "100%", background: '#160B25' }} >
                <Grid container direction="column" >

                  <Grid item style={{ textAlign: "end", background: '#160B25' }}>
                    <Button style={{ background: '#8F00BF', color: 'whitesmoke' }} onClick={() => addNew()}>Create New</Button>
                    <Img1 src="./assets/fon.png" />
                    <Img2 src="./assets/fonhero.png" />
                  </Grid>

                  <Grid item style={{ maxWidth: '100%', overflowX: "auto", overflowY: "auto", color: '#9156F1', background: '#160B25' }} >
                    <DataTable
                      rowsCount={totalCount}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      columns={columns}
                      data={dataList}
                      theme="solarized"
                      customStyles={customStyles}
                      responsive
                    />
                    <Grid container alignItems="center" justifyContent="end" marginTop={2}>
                      <Grid item>
                        <Pagination
                          classes={{ ul: classes.ul }}
                          style={{ color: '#a777f4' }}
                          sx={{ color: 'red', background: '' }}

                          color='primary'
                          variant="outlined"
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </Grid>
                      <Grid item padding="2%" style={{ color: '#925ae6' }}>
                        <div>{`Page: ${currentPage}`}</div>
                      </Grid>
                      <Grid item>
                        <FormControl
                          style={{ color: 'white', border: '1px solid #925ae6' }}>
                          <Select
                            sx={{ color: '#925ae6' }}
                            id="demo-select-small"
                            labelId="rows-per-page-label"
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                          >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Paper>
            </Grid>
          </Container>
          { loadingDiv ? 
                <div style={{position:"fixed",top:0,bottom:0,left:0,right:0,zIndex:9999,background:'rgba(128, 128, 128, 0.7)'}}>
                      
                      <div style={{position: 'absolute', left: '50%', top: '50%',width:'50%', transform: 'translate(-50%, -50%)'}}>
                        <Button onClick={(e) => confirmDialog(currentrow,2)}  style={{zIndex:9999,color:'#fff',fontSize:'20px',right:0,marginBottom:10,marginLeft:0}}><FontAwesomeIcon icon="fa-x" /></Button>
                        <ProgressBar completed={progressPercent} />
                        <br></br>
                        <div style={{color: '#fff',fontWeight: 'bolder', textAlign: 'center'}}>{statusUpdate}</div>
                      </div>
                </div> 
                : 
                <></> }
        </div>
      </>) : <></>}
    </>
  );
};

export default Dashboard;