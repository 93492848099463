export const heroOne = {
  reverse: false,
  inverse: true,
  topline:"Load Testing",
  headline: "Simplify load testing.",
  description:
    "We support formance testing for the widest range of protocols and more than 50 technologies and applicationenvironments.You can set up your performance testing easily, start testing with one click and get instant reports interactively. No need for writing code, no need for setting complex testing paths, and no need for investing in expensive resources and infrastructure",

  imgStart: "start",
  img:"./assets/svg/right.webp",
};
export const heroTwo = {
  reverse: false,
  inverse: true,
  topline:"Tools & Technologies",
  headline: "Find true power–in your data",
  description:
    "Join 2,000+ of the fastest growing Brands using crew performance to scale faster by centralising, analysing, and pushing their data.",
  img:"./assets/svg/right2.webp",
};
export const heroThree = {
  reverse: true,
  inverse: true,
  topline:"Emulation",
  headline: "Perfect Solution Thriving Online Business",
  description:
    "The software testing company offers thorough and streamlined testing processes using the latest tools and techniques. They have the expertise to test software across various platforms and versions, ensuring its quality and smooth performance.",
  lists:"true",
  linkTo: "/download",
  imgStart: "",
  img:"./assets/svg/left.webp",
  start: "true",
};

