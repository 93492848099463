import styled from "styled-components";

export const ColumnWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-top:-5%;
`

export const InfoWrapper = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
border:1px solid #C3C3C3;
text-align:start;
`
export const TextWrapper = styled.div`
>span{p,h2{
    margin-left:15%;
}}
>span{h2{
    padding-bottom:3%;
}}
p{
    padding:0;
    padding-bottom:1%;
    
}
@media screen and (max-width: 960px) {
    padding:15px 0 10px 10px;
    span{p,h2{
    margin-left:0;}}
}
`
export const FeatureTitle = styled.h2`
  font-size: clamp(1.5rem, 10vw, 2.9rem);
  line-height: 1.06;
  letter-spacing: 0rem;
  margin: auto;
  margin-bottom:5%;
  color: black;
  @media (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 2.4rem);
		margin-bottom:10%;
  }
`;

export const FeatureText = styled.p`
  text-align: start;
  font-size: 0.8rem;
  line-height: 1.43;
  color: #626881;
  padding:15px;
  font-weight: 500;


  @media screen and (max-width: 768px) {
      width:90%;
      font-size:0.6rem;
  }
`;
export const Icon = styled.div`
padding:5%;
@media screen and (max-width: 960px) {
    display:none;
}
`



export const Img =styled.img`
padding:10px;
`