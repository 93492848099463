import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Typography} from "@mui/material";
  import {
    FormButton ,
    FormMessage
  } from "../components/LoginForm/FormStyles";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import {config, getUserId , divStyle , paperCss} from '../Utils/Constants';
import ReCAPTCHA from "react-google-recaptcha";


const ForgotPassword = () => {
  const [username, setUsername]       = useState("");
  const [processsing,setProcessing]   = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [captcha , setCaptchaValue] = useState('');
  const [captchRefObj , setCaptchaRef] = useState(null);

  useEffect(() => {
    try 
    {
      		if(getUserId() != '')
      			window.location = '/dashboard';
    } catch (error) {
      console.log(error);
    }
  });

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  function onChange(value) {
    setCaptchaValue(value);
  }

  const reloadCaptcha = () => {
    captchRefObj.reset();
  }

  function sendResetLink(e)
  {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    if(processsing)
      return;

    setProcessing(true);
    Axios.post(config.api_url, {
      email : username,
      type : 'forgot',
      captcha : captcha
    }).then((res) => {
      try{reloadCaptcha();}catch(e){}
      setProcessing(false);
      if (res.status == "203") 
        setError("Captcha validation error");
      else
      {
        if(res.data['success'] == "1")
        {
            setUsername("");
            setSuccess('Reset link sent to your email , valid for 30 minutes');
        }
        else
            setError('Error resetting the password');
      }
    });
  }

  return (
    <div style={divStyle}>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
        >
          <Paper elelvation={2} sx={paperCss}>
            <form>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    fullWidth
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item>
                <ReCAPTCHA
                      ref={el => { setCaptchaRef(el); }}
                      sitekey={config.sitekey}
                      onChange={onChange}
                />
                <Button  onClick={() => reloadCaptcha()} color="info">Reload Captcha</Button>

                </Grid>
                <Grid item>
                  <Link to="/forgotPassword">
                    <FormButton onClick={(e) => sendResetLink(e)}>{processsing ? "Please wait..." : "Submit"}</FormButton>
                  </Link>
                </Grid>

                <Grid item>
                  <Link to="/login" style={{ textDecoration: 'none'}}>
                      <Typography fullWidth component="h6" variant="h6" style={{fontSize :15, color:'#408dce',textAlign: 'right' }}>
                        Login
                      </Typography>
                  </Link>
                </Grid>
              </Grid>
              {error && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                  error
                >
                  {error}
                </FormMessage>
              )}
              {success && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {success}
                </FormMessage>
              )}
            </form>
          </Paper>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;