import React, {useState} from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement
} from '@stripe/react-stripe-js';
import { getUserId ,config} from '../../Utils/Constants';
import {FormButton} from "../LoginForm/FormStyles";

export const CheckoutForm = (c) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading , setLoading] = useState(false);
  const [data,setData] = useState(c);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    if(loading)
      return;

    if (elements == null) {
      return;
    }

    const {error: submitError} = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    setLoading(true);
    const res = await fetch('/saas-api/', {
      method: 'POST',
      body: JSON.stringify({uid: getUserId(),type:'payobj',amount: c.card.cost,plan:c.card.id})
    });

    const {client_secret: clientSecret} = await res.json();

    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: config.site_url+'dashboard/',
      },
    });
    setLoading(false);

    if (error) {
      setErrorMessage(error.message);
    } else {

    }
  };

  return (
    <div style={{ paddingTop: '10%',paddingBottom:'10%',width:'100%',display: "flex",alignItems: "center", justifyContent: "center"}}>
    <div>
      <h4>Plan Name : {data.card.title}</h4> 
      <br></br>
      <h4>Plan Cost : {data.card.price}</h4> 
      <br></br><br></br><br></br>
      <AddressElement options={{ mode: "billing" }} />
      <PaymentElement />
      <FormButton onClick={handleSubmit} style={{background:'#8F00BF',border:'none',color:'#ffffff',fontSize:'12px',padding:'10px 5px'}} disabled={!stripe || !elements}>{loading ? "Please wait..." : "Pay Now"}</FormButton>
      {errorMessage && <div style={{width:'250px',textAlign:'justify',fontSize:'12px',marginTop:'20px'}}>{errorMessage}</div>}
    </div>
    </div>
  );
};