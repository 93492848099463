import React from "react";
import {
  FooterLogo,
  SocialIcon,
  FooterRights,
  FooterSocialIcon,
  FooterWrapper,
  FooterAddress,
  FooterColumn,
  FooterGrid,
  FooterContainer,
  NavMenu,
  NavItem,
  NavLinks,
  FormButton,
  FormInput,
  FormInputRow,
  Img2
} from "./FooterStyles";
import { footerSocialData } from "../../data/FooterData";
import { Row } from "../../globalStyles";
import { } from "../Navbar/NavbarStyles";
import { data_foot } from '../../data/NavbarData';
import { useLocation, useHistory } from 'react-router-dom';
function Footer() {
	let history = useHistory();
	let location = useLocation();

  const scrollTo = (id) => {
		const element = document.getElementById(id);

		element.scrollIntoView({
			behavior: 'smooth',
		});
	};
  const closeMobileMenu = (to, id) => {
		if (id && location.pathname === '/') {
			scrollTo(id);
		}

		history.push(to);
	};
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterGrid justify="space-between">
          <FooterColumn id="footerLogo">
            <FooterLogo to="/">
              <SocialIcon src="./assets/logo.png" />
            </FooterLogo>
            <FooterAddress>
              <p>Optimize your performance monitoring solutions with us.

                info@crewformance.com</p>
            </FooterAddress>
          </FooterColumn>
          <FooterColumn id="footerLogo">
            <FooterLogo>
              <h5>Quick Links</h5>
            </FooterLogo>
            <NavMenu >
              {data_foot.map((el, index) => (
                <NavItem key={index}>
                  <NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
                    {el.text}
                  </NavLinks>
                </NavItem>
              ))}
            </NavMenu>
          </FooterColumn>
          <FooterColumn>
            <FooterLogo to="/">
              <h5>Social Media</h5>
            </FooterLogo>
            <p style={{ color: 'white', marginBottom: '15px' }}>Subscribe to receive new blog posts from crew formance every other week in your <br/> inbox.</p>
            <Row align="center" gap="rem">
              {footerSocialData.map((social, index) => (
                <FooterSocialIcon
                  key={index}
                  href={social.site}
                  target="_blank"
                  aria-label={social.name}

                >
                  {social.icon}
                </FooterSocialIcon>
              ))}
            </Row>
          </FooterColumn>
        </FooterGrid>
        <FooterRights>
          © 2024 Crewformance ,All right Reserved
          <Img2 src="./assets/fon1.png" />
        </FooterRights>
      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;
