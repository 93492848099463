import styled from "styled-components";
import { motion } from "framer-motion";

export const HeroSection = styled.section`
  background: #0E0817;
  height:100vh;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  object-fit: cover;
  overflow: hidden;
  padding-top: clamp(70px, 25vh, 220px);
  padding-bottom: clamp(20px, 25vh, 100px);
  @media screen and (max-width: 768px) {
	  flex-direction: column-reverse;
  }
`;
export const Container = styled.div`
	width: 80%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 5px;
	object-fit: cover;
	display: flex;
	justify-content: space-between;
    align-items:center;
	@media screen and (max-width: 960px) {
		padding: 0 30px;
		display: flex;
		flex-direction: column;
		justify-content:center;
		align-items: center;
		
	}
`;
export const HeroPart = styled.div`
  width:45%;
  @media screen and (max-width: 768px) {
	margin-bottom:10%;
  @media screen and (max-width: 768px) {
    width:120%;
}
}
`;
  export const Heading = styled(motion.h2)`
  padding:10px;
  margin-top:5%;
  margin-bottom: 24px;
  font-size: 3.3rem;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing:-1px;
  z-index:1;
  span:first-child {
    background: linear-gradient(#C83D95, #8A14C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	font-size: 3rem;
	@media screen and (max-width: 960px) {
		font-size:2rem;
	  }
  }
  span:last-child {
  	color: white;
	font-size: 3rem;
	@media screen and (max-width: 960px) {
		font-size:2rem;
	  }
  }
	@media screen and (max-width: 768px) {
		font-size: 2rem;
		line-height: 1.1;
		margin-top:35%;

	}
`;
export const Img = styled(motion.img)`
  	width:100%;
	height:100%;
	vertical-align: middle;
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: repeat(3, 1fr);
	object-fit: cover;
	z-index: 50;
	padding:1.5%;
	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 3fr);
		grid-gap: 1.5rem;
	}
`;


export const HeroText = styled.p`
  margin-bottom: 35px;
  font-size: clamp(0.7rem, 1.3vw, 0.8rem);
  line-height: 24px;
  text-align: start;
  letter-spacing: 2px;
  color: #fff;
`;



export const Img1 = styled.img`
width:60%;
position:absolute;
top:50%;
left:0;
z-index: -1;
`

// under

export const ContentRow = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	align-items: center;
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

export const ContentColumn = styled(motion.div)`
	flex: 1;
	z-index: 10;
	display: flex;
	justify-content:space-around;
	align-items:center;
    padding:5% 0;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		display:flex;
		justify-content: center;
		align-items: center;
	}
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	@media screen and (max-width: 768px) {
		> h1,
		p {
			text-align: start;
		}
		display: flex;
		flex-direction: column;
		align-items: start;
		width:90%;
	}
`;

export const ImgWrapper = styled(motion.div)`
	display: flex;
	justify-content: 'flex-end';
	max-height: 700px;
	justify-content: center;
	position: relative;
	@media screen and (max-width: 768px) {
		weight:90%;
	}
`;

export const TopLine = styled(motion.div)`
    text-align:start; 
	font-size: 0.8rem;
	line-height: 16px;
	font-weight: 550;
	letter-spacing: 1.4px;
	margin-bottom: 1.3rem;
	color: grey;
	padding:10px;
	@media screen and (max-width: 768px) {
	margin-bottom: 0;
		
	}
`;

export const Img3 = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
	@media screen and (max-width: 768px) {
		width:90%;
	}
`;


export const Subtitle = styled(motion.p)`
	max-width: 440px;
    font-size: 0.8rem;
	line-height: 24px;
	color:black;
    text-align:start;
	padding:10px;

`;
export const Heading1 = styled(motion.h2)`
	margin-bottom: 1%;
	font-size: 2.2rem;
	line-height: 1.1;
	font-weight: 600;
	color: black;
    text-align:start;
	padding:10px;

	@media screen and (max-width: 768px) {
		text-align: start;
		font-size: 1.8rem;
		line-height: 1.1;
	}
`;


//focus
export const Text = styled(motion.div)`
display:flex;
flex-direction:column;
align-items:center;

`
export const PText = styled(motion.p)`
text-align:center; 
padding:1%;
font-size: 0.8rem;
font-weight:600;
color:grey;

`

export const PHead = styled(motion.h2)`
font-size: 1.9rem;
text-align:center; 
margin-bottom:3%;

`
export const Box = styled(motion.div)`
width:70%;
margin:0 auto;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 1.5rem;
@media screen and (max-width: 960px) {
	grid-template-columns: repeat(2, 3fr);
	grid-gap: 1.5rem;
}
`
export const BoxGroup = styled(motion.div)`
position:relative;

`
export const BoxText = styled(motion.h3)`
position:absolute;
top:10%;
left:10%;
	display: flex;	@media screen and (max-width: 768px) {
		font-size:0.5rem;
	}
`

