import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../globalStyles";
import {
  HeroSection,
  ButtonWrapper,
  Heading,
  HeroPart,
  Img,
  Container,
  FormInputRow,
  FormInput,
  FormLabel,
  FormButton,
  Img1,
  Img2,
  HeroButton,
} from "./HeroStyles";
import { getUserId } from '../../Utils/Constants';
import { Subtitle } from "../Content/ContentStyles";
import Cookies from 'js-cookie'

const Hero = ({ description }) => {
  const [loggedin, setLoggedIn] = useState(false);
  const [url , setUrl] = useState('');


  useEffect(() => {
    setLoggedIn(getUserId() != "");
  }, []);


  const handleChange=(e)=>{
      setUrl(e.target.value)
  }

  const submit = () =>{
      Cookies.set('url', url,{ expires: 1 });
      if(loggedin)
        window.location.href='/scenario_opt';
      else
        window.location.href='/login';
  }
  
  return (
    <HeroSection >
      <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Img1 src="./assets/fon.png"/>
      <Img2 src="./assets/fonhero.png"/>
        <HeroPart >
          <Heading><span>Reliable and Highly </span> <span>Scalable Performance Testing Service</span></Heading>
          <Subtitle>
            We guarantee a high-performance level for your digital products at
            operation peaks
            {description}
          </Subtitle>
            <FormInputRow style={{background:'transparent'}}>
            <FormInput
              style={{background:'white', borderRadius:'2rem', border:'1px solid #818181'}}
              placeholder='https://enterYourURL.com'
              onChange={handleChange}
            />
            <FormButton onClick={submit}>Start Testing</FormButton>
          </FormInputRow>
          {
        loggedin == 1 ?
        <ButtonWrapper>
          <Link to="/dashboard">
            <Button>DASHBOARD</Button>
          </Link>
        </ButtonWrapper>
        :
        <ButtonWrapper>
          <Link to="/login">
            <Button>LOG IN</Button>
          </Link>
          <Link to="/Register">
            <Button>REGISTER</Button>
          </Link>
        </ButtonWrapper>
        }
        </HeroPart>
        <HeroPart>
          <Img src="./assets/hero.jpg" alt="menu" />
        </HeroPart>
      </Container>
    </HeroSection>
  );
};

export default Hero;
