import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import {loadStripe} from '@stripe/stripe-js';
import HttpsRedirect from './HttpsRedirect';
//Pages
import Home from './pages/Home';
import SignUp from './pages/SignupPage';
import Pricing from './pages/PricingPage';
import Footer from './components/Footer/Footer';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import HistoryList from './pages/HistoryList';
import Scenario from './pages/Scenario';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import About from './components/About/About';
import LinkCase from './components/LinkCase/LinkCase';
import LinkContact from './components/LinkContact/LinkContact';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
export const stripePromise  = loadStripe('pk_live_51NZaG9G9ofP3l4BUAiZsad4voamnp3e7vsjW2vtCPzNTujOiAu5Fh4ow08v2JRrUhCBJaIkHIBGFVMY557G9LcJh001ZKQuzqF');


function App() {
	return (<>
		<BrowserRouter>
			<HttpsRedirect /> 
			<GlobalStyle />
			<Navbar />
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/about" exact component={About}/>
				<Route path="/case" exact component={LinkCase}/>
				<Route path="/contact" component={LinkContact}/>
				<Route path="/Register" exact component={SignUp} />
				<Route path="/pricing" exact component={Pricing} />
				<Route path="/Login" exact component={Login} />
				<Route path="/dashboard" exact component={Dashboard} />
				<Route path="/history" exact component={HistoryList} />
				<Route path="/scenario_opt" exact component={Scenario} />
				<Route path="/logout" exact component={Logout} />
				<Route path="/forgot" exact component={ForgotPassword} />
			</Switch>
			<Footer />
		</BrowserRouter>
		</>
	);
}

export default App;
