import React from 'react';
import { Content } from '../components/Content/Content';
import Hero from '../components/Hero/Hero';
import { heroOne, heroThree, heroTwo } from '../data/HeroData';
import Features from '../components/Features/Features';
import Why from '../components/Why/Why'
import Values from '../components/Values/Values'
import Contact from '../components/Contact/Contact'
import Pricing from '../components/Pricing/Pricing';
import Case from '../components/Case/Case';

// Hero Feature Content Carousel

const Home = () => {
	return (
		<>
			<Hero />
			<Features/>
			<div style={{background:'linear-gradient(#3D07BE,#6701D6,#960FAD)', zIndex:1}}>
			<Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
			</div>
			<Why/>
			<Values/>
			<Pricing/>
			<Contact/>
			<Case/>
		</>
	);
};

export default Home;
