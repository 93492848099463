import React from 'react';
import Form from '../components/RegistrationForm/Form';

function SignUp() {
	return (
		<>
			<Form />
		</>
	);
}

export default SignUp;
