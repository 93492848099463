import React, { useState, useEffect, useRef} from "react";
import {TextWrapper } from '../../globalStyles';
import {
	Elements
  } from '@stripe/react-stripe-js';
import {
	PricingSection,
	PricingWrapper,
	PricingContainer,
	PricingCardInfo,
	PricingCardPlan,
	PricingCardCost,
	PricingCardFeatures,
	PricingCardFeature,
	PricingCardText,
	PricingCard,
	Heading,
	PricingBox,
	PricingButton,
	MostButton,
} from './PricingStyles';
import {CheckoutForm} from '../Checkout/CheckoutForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from "axios";
import { config, getUserId} from '../../Utils/Constants';
import {stripePromise} from '../../App';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import ToggleButton from 'react-simple-switch';

const Pricing = () => {
	const [pricingData, setPlans]  = useState([]);
	const [checkout,setCheckout] = useState(0);
	const [options,setOptions] = useState({});
	const [card ,setCard] = useState(null);
	const [cid ,setCid] = useState('');
	const [indexHolder , setIndexHolder] = useState(1);

	useEffect(() => {
		if(pricingData.length == 0)
		{
			console.log('pr');
			loadPlans();
		}
		if(getUserId() != '')
		{
			Cookies.set('pricing','',{ expires: 1 });
		}
			
	})
	const bodyRef = useRef(null);
	const location = useLocation();
	useEffect(() => {
		if (location.pathname === '/pricing' && bodyRef.current) {
		  bodyRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  }, [location]);

	const loadPlans = () => {
		Axios.post(config.api_url, {
		  uid: getUserId(),
		  type: 'plans'
		}).then((res) => {
		  try {
			setPlans(res.data['plans']);
			setCid(res.data['cid']);
		  } catch (e) {console.log(e); }
		  
		});
	}

	const clicked = (card) => {
		try
		{
			try{console.log(getUserId()+" useerid");}catch(eee){console.log(eee);}
			if(getUserId() != '')
			{
				var opt = {
					mode: 'payment',
					amount: parseInt(card.cost) * 100,
					currency: 'usd',
					appearance: {
						theme: 'light',
						variables: {
						colorText: '#555',
						},
					},
					customer:cid
				};
		
				setOptions(opt);
				setCheckout(1);
				setCard(card);
			}
			else
			{
				Cookies.set('pricing','1',{ expires: 1 });
				window.location.href='/login';
			}
		}catch(e){
			console.log(e);
		}
		
	}

	const handleChange = (card,index) => {
		
		var list = card.list;
		var plist= pricingData;
		var c 	 = card.list[indexHolder];
		c.list 	 = list;
		plist[index] = c;
		if(indexHolder == 0)
			setIndexHolder(1);
		else
			setIndexHolder(0);
		
		setPlans(plist);
	}

	return (
		checkout == 1 ? 
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm card={card}/>
		</Elements>
		:
		<PricingSection id="pricing">
			<PricingWrapper>
				<TextWrapper
					spacing="-1px"
					mb="1rem"
					weight="580"
					size="0.8em"
					color="grey"
					align="center"

				>
					FLEXIBLE OPTIONS TO MATCH
				</TextWrapper>
				<Heading>Simplified pricing, unmatched value</Heading>

				<PricingContainer ref={bodyRef}>
					{pricingData.map((card, index) => (
						<PricingCard key={index} >
							<PricingCardInfo>
								{
									card.header_validitiy ?
									<h5 style={{color:'#0a0', marginLeft:'10px'}}>Expires on : {card.header_validitiy}</h5>
									:
									<h5>&nbsp;</h5>
								}
								{card.popular ? (<MostButton>{card.popular}</MostButton>)
								:
								( <></>)}
								<PricingBox>
									<PricingCardPlan>{card.title}</PricingCardPlan>
									<PricingCardCost>{card.info}</PricingCardCost>
									{card.is_unlimited == '0' ?
										<PricingCardPlan>{card.price} <span style={{ fontSize: '0.5em' }}>{card.duration}</span> </PricingCardPlan>
										:
										<PricingCardPlan>Get in Touch<span style={{ fontSize: '0.5em' }}></span> </PricingCardPlan>
									}

									<PricingCardText>{card.description}</PricingCardText>
								</PricingBox>
								<PricingCardFeatures>
									{card.features.map((feature, index) => (
										<PricingCardFeature key={index}>
											<FontAwesomeIcon icon="fa-solid fa-check" size="lg" style={{ color: "GrayText"}} />
											&nbsp;&nbsp;{feature}
										</PricingCardFeature>
									))}
								</PricingCardFeatures>

								{
								  card.list.length > 0 ?
								    <center>
										<span>{card.list[0]['toggle_txt']}</span>
										<span style={{display:'inline-block',margin:'0 10px'}}>
											<ToggleButton
												sytle={{display:'inline'}}
												onChange={() => handleChange(card,index)}
												buttonStates={card.list.length}
												textData={{ stateOne: card.list[0]['toggle_txt'].substring(0,1), stateTwo: card.list[1]['toggle_txt'].substring(0,1) }}
												fontSize='12px'
											/>
										</span>
										<span>{card.list[1]['toggle_txt']}</span>
									</center>
								  :
								  <div style={{visibility: 'hidden'}}>
								  	<ToggleButton
									  
										fontSize='12px'
								  	/>
								  </div>
								}

								{card.is_unlimited == '1' ?
									<><h5>&nbsp;</h5><br></br><PricingButton style={{visibility:'hidden'}}>&nbsp;</PricingButton><a href='mailto:info@crewload.crewformance.com'><PricingButton style={{background:'#8F00BF'}}>Contact Us</PricingButton></a></>
									:
									card.is_active == '0' && card.disabled == '0' ? (<><h5>&nbsp;</h5><br></br><PricingButton onClick={() => clicked(card)} style={{background:'#8F00BF'}}>Get Started</PricingButton></>)
									: (card.disabled == '1' ? <><h5>&nbsp;</h5><br></br><PricingButton>Not Applicable</PricingButton></> : (card.cost == '0' ? <><h5>&nbsp;</h5><br></br><PricingButton>Current Plan</PricingButton></> : <><h5>&nbsp;</h5><br></br><PricingButton onClick={() => clicked(card)} style={{background:'#0066CC'}}>Renew Again</PricingButton></>))
								}
							</PricingCardInfo>
						</PricingCard>
					))}
				</PricingContainer>
			</PricingWrapper>
		</PricingSection>
	);
}
export default Pricing;
