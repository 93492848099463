import React, { useEffect } from 'react';
import { Container, Section } from '../../globalStyles';
import {
    FeatureText,
    FeatureWrapper,
    FeatureColumn,
    FeatureImageWrapper,
    FeatureName,
    Heading,
    TextWrapper,
    FeatureTitle,
    ValuesNum,
    Img,
} from './ValuesStyles';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

const Features = () => {
    const initial = { opacity: 0, y: 50 };
    const animation = useAnimation();

    const { ref, inView } = useInView({ threshold: 0.2 });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
    }, [inView, animation]);

    return (
        <Section smPadding="50px 10px" inverse id="" ref={ref} style={{ background: '#0E0818' }}>
            <Container>
                <TextWrapper>
                    <FeatureText><span>CREWFORMANCE VALUES</span></FeatureText>
                    <FeatureTitle><span>World-acclaimed</span> <br /><span>leaders the field</span></FeatureTitle>
                    <FeatureText>Are you sure your applications and systems have a high <br /> performance? If not, let's catch up.</FeatureText>
                    <Img src='./assets/underwhy.png'/>
                </TextWrapper>

                <FeatureWrapper>
                    <FeatureColumn
                        initial={initial}
                        transition={{ delay: 0.3, duration: 0.6 }}
                        animate={animation}
                    >
                        <ValuesNum>25+</ValuesNum>
                    <FeatureText>Year of Excllence</FeatureText>


                    </FeatureColumn>
                    <FeatureColumn
                        initial={initial}
                        transition={{ delay: 0.3, duration: 0.6 }}
                        animate={animation}
                    >
                        <ValuesNum>100+</ValuesNum>
                    <FeatureText>Performance Engineers</FeatureText>


                    </FeatureColumn>
                    <FeatureColumn
                        initial={initial}
                        transition={{ delay: 0.3, duration: 0.6 }}
                        animate={animation}
                    >
                        <ValuesNum>500+</ValuesNum>
                    <FeatureText>Clients</FeatureText>

                    </FeatureColumn>
                    <FeatureColumn
                        initial={initial}
                        transition={{ delay: 0.3, duration: 0.6 }}
                        animate={animation}
                    >
                        <ValuesNum>1000+</ValuesNum>
                    <FeatureText>Project Complated</FeatureText>
                    </FeatureColumn>
                </FeatureWrapper>
            </Container>
        </Section>
    );
};

export default Features;
