// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backArrow {
	position: absolute;
	top: 40%;
	left: 5%;
	color:#fff;
	z-index: 100;
	cursor:pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,QAAQ;CACR,QAAQ;CACR,UAAU;CACV,YAAY;CACZ,cAAc;EACb","sourcesContent":[".backArrow {\n\tposition: absolute;\n\ttop: 40%;\n\tleft: 5%;\n\tcolor:#fff;\n\tz-index: 100;\n\tcursor:pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
