export const data = [
	{
		to: '/',
		text: 'Home',
		id: 'about',
	},

	{
		to: '/about',
		text: 'About us',
	},
	{
		to: '/pricing',
		text: 'Pricing',
	},
	{
        to: 'https://docs.crewformance.com',
        text: 'Documentation',
        isExternal: true,
    },
	{
		to: '/contact',
		text: 'Contact',
	}
];

export const data_reg = [
	{
		to: '/dashboard',
		text: 'Home',
		id: 'about',
	},

	{
		to: '/about',
		text: 'About us',
	},
	{
		to: '/pricing',
		text: 'Pricing',
	},
	{
        to: 'https://docs.crewformance.com',
        text: 'Documentation',
        isExternal: true,
    },
	{
		to: '/contact',
		text: 'Contact',
	},
	{
		to: '/logout',
		text: 'Logout',
	},

];
export const data_foot = [
	{
		to: '/',
		text: 'Home',
		id: 'about',
	},

	{
		to: '/about',
		text: 'About us',
	},
	{
		to: '/pricing',
		text: 'Pricing',
	},
	{
        to: 'https://docs.crewformance.com',
        text: 'Documentation',
        isExternal: true,
    },
	{
		to: '/contact',
		text: 'Contact',
	},
	{
		to: '/case',
		text: 'Case',
	},

];
