import styled from "styled-components";
import { Column, Row } from "../../globalStyles";
export const FooterContainer = styled.div`
 background-color:#0E0817;
 position:relative;
 z-index:0;
 height:100%;
 
 `;
 
 export const FooterWrapper = styled.div`
 margin:auto 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  ;
`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`;

export const FooterSubHeading = styled.p`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 24px;
  font-size: 24px;
`;

export const FooterRow = styled(Row)`
  flex-wrap: wrap;
  @media screen and (max-width: 820px) {
    > div {
      width: 20%;
    }

    > div:first-child {
      width: 100%;
    }
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: center;

    * {
      width: 100%;
      text-align: center;
    }
  }
`;

export const FooterGrid = styled.div`
  display: grid;
  padding-top:8%;
  justify-items: start;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  @media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 3fr);
		grid-gap: 1.5rem;
	}
`;

export const FooterColumn = styled(Column)`
display:flex;
flex:direction:column;
align-items:start;
text-align:start;
padding:10px;
padding-left:10%;
  @media screen and (max-width: 999px) {
  padding-left:15px;
  }
`;
export const FormInputRow = styled.div`
display: flex;
justify-content: center;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1.4rem;
  position:relative;
  width:100%;
	> p {
    font-size: 0.8rem;
		margin-top: 0.5rem;
		color: #f00e0e;
	}
  `;
  export const FormInput = styled.input`
	display: inline-block;
	padding-left: 5px;
	border-radius: 2px; 
	width: 100%;
	border: none;
	border-bottom: 1px solid #818181;
	font-size: 1rem;
  padding:10px 0px 10px 10px;
  @media screen and (max-width: 768px) {
		width:100%;
    height:100%;
	}
`;

export const FormButton = styled.button`
  border-radius:3rem;
  padding:0% 10% 0 10%;
  background:white;
  color:black;
  font-weight:700;
  position:absolute;
  right:0;
  border:none;
  min-height:100%;
  @media screen and (max-width: 768px) {
    position:absolute;
	}
 
`

export const FooterLogo = styled.div`
  color: #fff;
  justify-self: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h5{
  font-size: 1.1rem;

  }

`;
export const FooterRights = styled.div`
  color: #fff;
  margin-bottom: 16px;
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem 0;
  margin: 1rem 0 0;
`;
export const FooterSocialIcon = styled.a`
	color: #fff;
	font-size: 24px;
  padding-right:10px;
  padding: 15px 10px 10px 0;
`;
export const SocialIcon = styled.img`

`

export const FooterAddress = styled.div`
  color: white;
  max-width: 10rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2;
  >p{
    text-align: start;
  }

  @media screen and (min-width: 1000px) {
    margin-left: 0px;
    text-align: center;
    margin-right: 1rem;
  }
`;
export const NavMenu = styled.ul`
	display: flex;
  flex-direction:column;
	align-items: start;
	list-style: none;
	width: 100%;
  `
  export const NavItem = styled.li`
	cursor: pointer;

	@media screen and (max-width: 960px) {
		width: 100%;

		&:hover {
			border: none;
		}
	}
`

export const NavLinks = styled.span`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 7% 0%;
	height: 100%;
  cursor:pointer;
  font-size:1rem;
`;

export const Img2 = styled.img`
position:absolute;
left:0;
top:0;
z-index: -1;
width:28%;
max-height:100%
`