import React, { useEffect } from 'react';

const HttpsRedirect = () => {
  useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, []); 

  return null;
};

export default HttpsRedirect;