import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
    HeroSection,
    HeroPart,
    Img,
    Img1,
    ContentColumn, 
    ImgWrapper, 
    Subtitle,
     TopLine, 
     Img3, 
     Heading1, 
     Text, PText, PHead, Box, BoxGroup, BoxText, Container, HeroText
} from './AboutStyles';
import { Section, TextWrapper } from '../../globalStyles';
import Why from "../Why/Why"
import Values from "../Values/Values"
import Contact from '../Contact/Contact';
import Case from '../Case/Case';
import { ContentRow } from '../Content/ContentStyles';
import { Heading} from '../Hero/HeroStyles';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import axios from 'axios';

const About = () => {
    const initial = { opacity: 0, y: 50 };
    const animation = useAnimation();
    const [users, setUsers] = useState([]);
    const { ref, inView } = useInView({ threshold: 0.2 });
    const bodyRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });
        }
        if (location.pathname === '/about' && bodyRef.current) {
            bodyRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        axios.get('https://641d854f4366dd7def3fd2b0.mockapi.io/g/users')
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [inView, animation, location]);
    return (
        <div>
            <HeroSection ref={bodyRef}>
                <Container>
                    <HeroPart >
                        <Img1 src="./assets/fonabout.png" />
                        <Heading><span>Top Performance </span> <span>Monitoring Firm</span></Heading>
                        <HeroText>
                            We integrate quality engineering and best practices across your IT
                            infrastructure and enhance application performance.
                        </HeroText>
                    </HeroPart>
                    <HeroPart>
                        <div style={{ border: '3px solid #100C34', borderRadius: '50%' }}>
                            <Img src="./assets/imgabout.png" alt="menu" />
                        </div>
                    </HeroPart>
                </Container>
            </HeroSection>
            <Section ref={ref}>
                <ContentRow>
                    <ContentColumn style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <TextWrapper>
                            <TopLine id="about"
                                initial={initial}
                                transition={{ delay: 0.3, duration: 0.6 }}
                                animate={animation}
                            >
                                Our mission
                            </TopLine>
                            <Heading1
                                initial={initial}
                                transition={{ delay: 0.3, duration: 0.6 }}
                                animate={animation}
                            >
                                New paths to growth
                            </Heading1>
                            <Subtitle
                                initial={initial}
                                transition={{ delay: 0.3, duration: 0.6 }}
                                animate={animation}
                            >
                                Crew Formance aims to make it easy to build your own web performance testing suite on a comprehensive load testing platform. Our solution, which is built on modern infrastructure, offers a wide range of configuration choices to load-test your website quickly and efficiently. Through Crew Formance, you can control the number of simulated users, type of browser, and test duration.
                                Being able to test your website performance through a cloud-based solution helps you to focus on achieving your business goals. Crew Formance will carry out the performance testing mission on your behalf, using a comprehensive and simple-to-use test control board. Crew Formance streamlines your performance testing journey with a short learning curve, no coding headaches, and real-time data.
                            </Subtitle>
                        </TextWrapper>
                    </ContentColumn>
                    <ContentColumn>
                        <ImgWrapper>
                            <Img
                                initial={initial}
                                transition={{ delay: 0.3, duration: 0.6 }}
                                animate={animation}
                                src='./assets/underabout1.png'
                                alt='foto'
                            />

                        </ImgWrapper>
                    </ContentColumn>
                </ContentRow>
                <ContentRow>
                    <ContentColumn>
                        <ImgWrapper>
                            <Img
                                src='./assets/underabout2.png'
                                alt='foto'
                                whileHover={{ rotate: 2, scale: 1.02 }}
                                transition={{ duration: 0.5 }}
                            />

                        </ImgWrapper>
                    </ContentColumn>
                    <ContentColumn>
                        <TextWrapper>
                            <TopLine id="about">
                                ABOUT US
                            </TopLine>
                            <Heading1 >
                                What is Crewformance ?
                            </Heading1>
                            <Subtitle>
                                Crew Formance is a Platform - Based Performance Testing solution trusted by thousands of companies around the globe. You can set up your performance testing easily, start testing with one click and get instant reports interactively. No need for writing code, no need for setting complex testing paths, and no need for investing in expensive resources and infrastructure. <br />
                                Crew Formance is the "Everything You Need" solution to evaluate the performance of your website. You have a wide range of options and settings such as simulating the authentication process, filling forms with prepared test data, navigating through your web app, handling pop-up screens, setting up pre-defined test paths, and more options for you.
                            </Subtitle>
                        </TextWrapper>
                    </ContentColumn>
                </ContentRow>
            </Section>

            <div style={{ background: 'linear-gradient(#3D07BE,#6701D6,#960FAD)' }}>
                <div style={{ width: '95%', margin: '0 auto' }}>
                    <ContentRow>
                        <ContentColumn>
                            <TextWrapper>
                                <TopLine id="about"
                                    style={{ color: '#F76BF0' }}
                                    transition={{ delay: 0.3, duration: 0.6 }}

                                >
                                    OUR VALUES
                                </TopLine>
                                <Heading1
                                    style={{ color: 'white' }}
                                    transition={{ delay: 0.5, duration: 0.6 }}

                                >
                                    Why use Crewformance
                                </Heading1>
                                <Subtitle
                                    style={{ color: 'white' }}
                                    transition={{ delay: 0.7, duration: 0.6 }}

                                >
                                    Crew Formance discovers performance issues in your system early on,
                                    allowing you to rectify them during the development phase. This results in efficient cost reduction and early market entry for your business. Crew Formance allows you to assess how well your system is doing and anticipate when it may slow down or crash. <br />
                                    Crew Formance assists in identifying bottlenecks, places of weakness,
                                    slowdowns, and failures in applications that are subjected to high traffic. You would have to include Crew Formance into your quality operations for a variety of reasons:
                                </Subtitle>
                            </TextWrapper>
                        </ContentColumn>
                        <ContentColumn

                            transition={{ delay: 0.5, duration: 0.6 }}

                        >
                            <ImgWrapper>
                                <Img3
                                    src="./assets/svg/right.png"
                                    alt='foto'
                                    whileHover={{ rotate: 2, scale: 1.02 }}
                                    transition={{ duration: 0.5 }}
                                />

                            </ImgWrapper>
                        </ContentColumn>
                    </ContentRow>
                </div>
            </div>
            <Why />
            <Values />
            <Section>
                <Text>
                    <PText>
                        FOCUS AREAS
                    </PText>
                    <PHead>
                        Industries we Serve
                    </PHead>
                </Text>
                <Box>
                {users.map((el, index) => (
                    <BoxGroup key={index}>
                        <Img src={el.avatar}/>
                        <BoxText>{el.name}</BoxText>
                    </BoxGroup>
                 ))}
                </Box>
            </Section>
            <Contact/>
            <Case/>
        </div>
    );
}

export default About;
