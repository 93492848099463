import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
		site:'https://www.facebook.com/TestCrewCo'

	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
		site:'https://www.instagram.com/testcrewco/'	
	},
	{
		name: 'YouTube',
		icon: iconStyle(FaYoutube),
		site:'https://www.youtube.com/@testcrew7173'
	},
	{
		name: 'LinkedIn',
		icon: iconStyle(FaLinkedin),
		site:'https://www.linkedin.com/company/the-testcrew/'

	},
];

export const footerData = [
	{
		title: 'Main',
		links: ['Blog', 'FAQs', 'Support', 'About us'],
	},
	{
		title: 'Product',
		links: ['Login', 'Personal', 'Business', 'Team'],
	},
	{
		title: 'Press',
		links: ['Logos', 'Events', 'Stories', 'Office'],
	},
];
