import React, { useEffect, useRef, useState } from "react";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
  Img1,
  Img2,
  VisiblityPassword
} from "./FormStyles";
import { config } from '../../Utils/Constants';
import {messageVariants} from "../LoginForm/FormStyles";
import { Container } from "../../globalStyles";
import validateForm from "./validateForm";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { Box, Button, Modal, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Icon.css'

const Form = () => {
  const [name, setName] = useState("");
  const [Fname, setFName] = useState("");
  const [processsing,setProcessing] = useState(false);
  const [org, setOrg] = useState("");
  const location = useLocation();
  const bodyRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [captcha , setCaptchaValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [captchRefObj , setCaptchaRef] = useState(null);
  const [open, setOpen] = React.useState(false);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(()=>{
    if (location.pathname === '/register' && bodyRef.current) {
      bodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  },[location])

  function onChange(value) {
    setCaptchaValue(value);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const resultError = validateForm({
      Fname,
      name,
      org,
      email,
      password,
      confirmPass,
    });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    setError(null);
    if(processsing)
      return;

    setProcessing(true);
    Axios.post(config.api_url, {
      username: name,
      name: Fname,
      email: email,
      role: "admin",
      password: password,
      phone: "05555551555",
      org_name: org,
      captcha:captcha,
      type:'register'
    }).then((res) => {
        try{reloadCaptcha();}catch(e){}
        try
      	{
          console.log(res.data);
          if (res.status == "203") 
            setError("Captcha validation error");
          else
          {
            if(res.data['success'] == '1')
            {
              setFName(""); 
              setName("");
              setOrg("");
              setEmail("");
              setPassword("");
              setConfirmPass("");    
              setSuccess(res.data['message']);
            } 
            else
              setError(res.data['message']);
         }
      	}catch(e){ console.log(e)}    
      })
      .catch(err => {
        console.log(err);
      }).then(() => { setProcessing(false); }) ;  
  };


  const reloadCaptcha = () => {
    captchRefObj.reset();
  }


  const formData = [
    {
      label: "Full Name",
      value: Fname,
      onChange: (e) => setFName(e.target.value),
      type: "text",
    },
    {
      label: "Username",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Organization",
      value: org,
      onChange: (e) => setOrg(e.target.value),
      type: "text",
    },
    {
      label: "Email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      type: "password",
    },
    {
      label: "Confirm Password",
      value: confirmPass,
      onChange: (e) => setConfirmPass(e.target.value),
      type: "password",
    },
  ];

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn small>
            <FormTitle ref={bodyRef}>Registration</FormTitle>
            <Img1 src="./assets/fon.png"/>
              <Img2 src="./assets/fonhero.png"/>
            <FormWrapper onSubmit={handleSubmit}>
              {formData.map((el, index) => (
                <FormInputRow key={index} style={{background:'transparent'}}>
                  <FormLabel style={{color:'white'}}>{el.label}</FormLabel>
                  <FormInput
                    type={el.type === 'password' && showPassword ? 'text' : el.type}
                    value={el.value}
                    onChange={el.onChange}
                  />
                    {el.type === 'password' && (
                      <VisiblityPassword className="password-toggle" onClick={togglePasswordVisibility}>
                        {
                          showPassword ?
                            <FontAwesomeIcon icon="fa-solid fa-eye" />
                            :
                            <FontAwesomeIcon icon="fa-solid fa-eye-slash" />
                        }

                      </VisiblityPassword>
                    )}
                </FormInputRow>
              ))}

              <ReCAPTCHA
                      ref={el => { setCaptchaRef(el); }}
                      sitekey={config.sitekey}
                      onChange={onChange}
              />

              <Button  onClick={() => reloadCaptcha()} color="info">Reload Captcha</Button>

              <FormButton onClick={handleOpen}  type="submit">{processsing ? "Please wait..." : "Submit"}</FormButton>
            </FormWrapper>
            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
          </FormColumn>
        </FormRow>
        {success && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='boxstyle'>
              <FontAwesomeIcon icon="fa-solid fa-circle-check" beat className="icon" />
              <Typography id="modal-modal-description" sx={{ p: 2, fontWeight: 600 }}>
                {success}
              </Typography>
            </Box>
          </Modal>
        )}

      </Container>
    </FormSection>
  );
};

export default Form;
