
import React, { useState, useEffect } from "react";
import {setUserId,getUserId} from '../Utils/Constants';
  
  const Logout = () => {  
    useEffect(() => {
      // setUserId(0);
      document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      });
      window.location   = '/';
    },[]);


    return (
        <>
        </>
    );
  };
  
  export default Logout;
  
  